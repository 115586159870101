import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Tooltip,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Edit,
  DeleteForever,
} from "@mui/icons-material";
import axios from "axios";
import { Autocomplete } from "@react-google-maps/api";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "./SearchSection.css";

const FlightSearchSection = ({ onFlightSearch }) => {
  const [fromLocation, setFromLocation] = useState("New Delhi");
  const [toLocation, setToLocation] = useState("Bangalore");
  const [departureDate, setDepartureDate] = useState(
    dayjs(new Date(new Date().setDate(new Date().getDate() + 1)))
  );
  const [returnDate, setReturnDate] = useState(
    dayjs(new Date(new Date().setDate(new Date().getDate() + 1)))
  );
  const navigate = useNavigate();
  const [checkOut, setCheckOut] = useState(
    dayjs(new Date(new Date().setDate(new Date().getDate() + 3)))
  );
  const [guests, setGuests] = useState(1);
  const [travellers, setTravellers] = useState(1);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [paxes, setPaxes] = useState([]);
  const [isGuestsDialogOpen, setIsGuestsDialogOpen] = useState(false);
  const [isChildAgeDialogOpen, setIsChildAgeDialogOpen] = useState(false);
  const [childAge, setChildAge] = useState("");
  const [fromCoordinates, setFromCoordinates] = useState(null);
  const [toCoordinates, setToCoordinates] = useState(null);
  const [isDepartureDateInputVisible, setIsDepartureDateInputVisible] =
    useState(false);
  const [isReturnDateInputVisible, setIsReturnDateInputVisible] =
    useState(false);
  const [isCheckOutInputVisible, setIsCheckOutInputVisible] = useState(false);
  const [isFromLocationInputVisible, setIsFromLocationInputVisible] =
    useState(false);
  const [isToLocationInputVisible, setIsToLocationInputVisible] =
    useState(false);
  const [isGuestsInputVisible, setIsGuestsInputVisible] = useState(false);
  const [editChildIndex, setEditChildIndex] = useState(null);
  const [fromAirportData, setFromAirportData] = useState(null);
  const [toAirportData, setToAirportData] = useState(null);
  const [isFromAirportDialogOpen, setIsFromAirportDialogOpen] = useState(false);
  const [isToAirportDialogOpen, setIsToAirportDialogOpen] = useState(false);
  const [tripType, setTripType] = useState("Oneway");
  const autocompleteRef = useRef(null);
  const handleAddChildAge = () => {
    setChildAge("");
    setEditChildIndex(null);
    setIsChildAgeDialogOpen(true);
  };
  const handleChildAgeSubmit = () => {
    if (editChildIndex !== null) {
      // Editing an existing child
      const updatedPaxes = [...paxes];
      updatedPaxes[editChildIndex].age = parseInt(childAge);
      setPaxes(updatedPaxes);
    } else {
      // Adding a new child
      setPaxes([...paxes, { type: "CH", age: parseInt(childAge) }]);
      setChildren(children + 1);
    }
    setChildAge("");
    setIsChildAgeDialogOpen(false);
  };
  const handleGuestsDialogOpen = () => setIsGuestsDialogOpen(true);
  const handleGuestsDialogClose = () => setIsGuestsDialogOpen(false);
  const updateGuestsCount = () => {
    setGuests(adults + children);
    handleGuestsDialogClose();
  };
  const handleFromPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setFromCoordinates({ latitude: lat, longitude: lng });
    fetchNearbyAirportFrom(lat, lng);
  };

  const handleToPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setToCoordinates({ latitude: lat, longitude: lng });
    fetchNearbyAirportTo(lat, lng);
  };

  const fetchNearbyAirportFrom = async (lat, lng) => {
    try {
      const response = await axios.get(
        `http://www.iatageo.com/getCode/${lat}/${lng}`
      );
      setFromAirportData(response.data);
      console.log("fromAirportData=", response.data);
      setIsFromAirportDialogOpen(true); // Show dialog after fetching airport data
    } catch (error) {
      console.error("Error fetching airport data:", error);
    }
  };

  const fetchNearbyAirportTo = async (lat, lng) => {
    try {
      const response = await axios.get(
        `http://www.iatageo.com/getCode/${lat}/${lng}`
      );
      setToAirportData(response.data);
      console.log("toAirportData=", response.data);
      setIsToAirportDialogOpen(true); // Show dialog after fetching airport data
    } catch (error) {
      console.error("Error fetching airport data:", error);
    }
  };

  const handleSelectAirportFrom = () => {
    if (fromAirportData) {
      setFromLocation(fromAirportData.name); // Set the airport name
      setIsFromAirportDialogOpen(false); // Close the dialog
      setIsFromLocationInputVisible(false);
    }
  };

  const handleSelectAirportTo = () => {
    if (toAirportData) {
      setToLocation(toAirportData.name); // Set the airport name
      setIsToAirportDialogOpen(false); // Close the dialog
      setIsToLocationInputVisible(false);
    }
  };

  const handleFlightSearch = () => {
    // onFlightSearch({
    //   fromLocation,
    //   toLocation,
    //   departureDate: departureDate.format("YYYY-MM-DD"),
    //   checkOut: checkOut.format("YYYY-MM-DD"),
    //   guests,
    //   adults,
    //   children,
    //   paxes,
    //   travellers,
    //   fromCoordinates,
    //   toCoordinates,
    // });
    const flightSearchParams = {
      fromLocation,
      toLocation,
      departureDate: departureDate.format("YYYY-MM-DD"),
      adults,
      children,
      paxes: [],
      fromAirportData,
      toAirportData,
      tripType,
      returnDate: returnDate.format("YYYY-MM-DD"),
    };

    // Navigate to FlightResults page and pass the search parameters
    navigate("/flight-results", { state: { flightSearchParams } });
  };

  const toggleDepartureDateInput = () => {
    setIsDepartureDateInputVisible(!isDepartureDateInputVisible);
  };

  const toggleReturnDateInput = () => {
    setIsReturnDateInputVisible(!isReturnDateInputVisible);
  };

  const toggleCheckOutInput = () => {
    setIsCheckOutInputVisible(!isCheckOutInputVisible);
  };

  const formatDate = (date) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const getWeekDay = (date) => {
    const options = { weekday: "long" };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  const handleEditChildAge = (index) => {
    setChildAge(paxes[index].age.toString());
    setEditChildIndex(index);
    setIsChildAgeDialogOpen(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{ width: "100%", position: "relative", textAlign: "center" }}
        className="search-section-container"
      >
        <Box display="flex" flexDirection="column" width="100%">
          <Box sx={{ marginLeft: "15%" }}>
            <RadioGroup
              row
              value={tripType}
              onChange={(e) => setTripType(e.target.value)}
              sx={{ mb: 2 }}
            >
              <FormControlLabel
                value="Oneway"
                control={<Radio />}
                label="Oneway"
              />
              <FormControlLabel
                value="Return"
                control={<Radio />}
                label="Return"
              />
            </RadioGroup>
          </Box>
          {tripType === "Oneway" && (
            <Box display="flex" justifyContent="center" flexGrow={1}>
              <div className="search-fields filter-container">
                <Tooltip
                  title="Enter the location to search nearby airports"
                  placement="top"
                  arrow
                >
                  <div
                    className="search-field"
                    onClick={() =>
                      setIsFromLocationInputVisible(!isFromLocationInputVisible)
                    }
                  >
                    {isFromLocationInputVisible ? (
                      <Autocomplete
                        onLoad={(autocomplete) =>
                          (autocompleteRef.current = autocomplete)
                        }
                        onPlaceChanged={handleFromPlaceChanged}
                      >
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={fromLocation}
                          onChange={(e) => {
                            setFromLocation(e.target.value);
                          }}
                          autoFocus
                          placeholder="Enter from location"
                        />
                      </Autocomplete>
                    ) : (
                      <>
                        <div className="search-field-label">From</div>
                        <div className="search-field-value">{fromLocation}</div>
                      </>
                    )}
                  </div>
                </Tooltip>
                <Dialog
                  open={isFromAirportDialogOpen}
                  onClose={() => setIsFromAirportDialogOpen(false)}
                >
                  <DialogTitle>Nearby Airport</DialogTitle>
                  <DialogContent>
                    {fromAirportData && (
                      <Box>
                        <Typography>
                          Airport Name: {fromAirportData.name} (IATA:{" "}
                          {fromAirportData.IATA})
                        </Typography>
                        <Typography>
                          Distance: {fromAirportData.distance_meters} meters
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSelectAirportFrom}
                          style={{ marginTop: "10px" }}
                        >
                          Select
                        </Button>
                      </Box>
                    )}
                  </DialogContent>
                </Dialog>
                <Tooltip
                  title="Enter the location to search nearby airports"
                  placement="top"
                  arrow
                >
                  <div
                    className="search-field"
                    onClick={() =>
                      setIsToLocationInputVisible(!isToLocationInputVisible)
                    }
                  >
                    {isToLocationInputVisible ? (
                      <Autocomplete
                        onLoad={(autocomplete) =>
                          (autocompleteRef.current = autocomplete)
                        }
                        onPlaceChanged={handleToPlaceChanged}
                      >
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={toLocation}
                          onChange={(e) => {
                            setToLocation(e.target.value);
                          }}
                          autoFocus
                          placeholder="Enter to location"
                        />
                      </Autocomplete>
                    ) : (
                      <>
                        <div className="search-field-label">To</div>
                        <div className="search-field-value">{toLocation}</div>
                      </>
                    )}
                  </div>
                </Tooltip>
                <Dialog
                  open={isToAirportDialogOpen}
                  onClose={() => setIsToAirportDialogOpen(false)}
                >
                  <DialogTitle>Nearby Airport</DialogTitle>
                  <DialogContent>
                    {toAirportData && (
                      <Box>
                        <Typography>
                          Airport Name: {toAirportData.name} (IATA:{" "}
                          {toAirportData.IATA})
                        </Typography>
                        <Typography>
                          Distance: {toAirportData.distance_meters} meters
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSelectAirportTo}
                          style={{ marginTop: "10px" }}
                        >
                          Select
                        </Button>
                      </Box>
                    )}
                  </DialogContent>
                </Dialog>

                <div className="search-field">
                  {isDepartureDateInputVisible ? (
                    <DatePicker
                      label="Departure"
                      fullWidth
                      value={departureDate}
                      onChange={(newDate) => setDepartureDate(newDate)}
                      onAccept={toggleDepartureDateInput}
                      onClose={toggleDepartureDateInput}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  ) : (
                    <>
                      <div
                        className="search-field-label"
                        onClick={toggleDepartureDateInput}
                      >
                        Departure
                      </div>
                      <div
                        className="search-field-value"
                        onClick={toggleDepartureDateInput}
                      >
                        {formatDate(departureDate)}
                      </div>
                      <div
                        className="search-field-label"
                        onClick={toggleDepartureDateInput}
                      >
                        {getWeekDay(departureDate)}
                      </div>
                    </>
                  )}
                </div>

                <div className="search-field" onClick={handleGuestsDialogOpen}>
                  <div className="search-field-label">Travelers & Class</div>
                  <div className="search-field-value">
                    {travellers} Travellers, Class
                  </div>
                </div>
              </div>
              <div className="search-button-container">
                <Button
                  className="search-button"
                  variant="contained"
                  color="primary"
                  onClick={handleFlightSearch}
                >
                  Search
                </Button>
              </div>
            </Box>
          )}
          {tripType === "Return" && (
            <Box display="flex" justifyContent="center" flexGrow={1}>
              <div className="search-fields-return filter-container">
                <Tooltip
                  title="Enter the location to search nearby airports"
                  placement="top"
                  arrow
                >
                  <div
                    className="search-field"
                    onClick={() =>
                      setIsFromLocationInputVisible(!isFromLocationInputVisible)
                    }
                  >
                    {isFromLocationInputVisible ? (
                      <Autocomplete
                        onLoad={(autocomplete) =>
                          (autocompleteRef.current = autocomplete)
                        }
                        onPlaceChanged={handleFromPlaceChanged}
                      >
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={fromLocation}
                          onChange={(e) => {
                            setFromLocation(e.target.value);
                          }}
                          autoFocus
                          placeholder="Enter from location"
                        />
                      </Autocomplete>
                    ) : (
                      <>
                        <div className="search-field-label">From</div>
                        <div className="search-field-value">{fromLocation}</div>
                      </>
                    )}
                  </div>
                </Tooltip>
                <Dialog
                  open={isFromAirportDialogOpen}
                  onClose={() => setIsFromAirportDialogOpen(false)}
                >
                  <DialogTitle>Nearby Airport</DialogTitle>
                  <DialogContent>
                    {fromAirportData && (
                      <Box>
                        <Typography>
                          Airport Name: {fromAirportData.name} (IATA:{" "}
                          {fromAirportData.IATA})
                        </Typography>
                        <Typography>
                          Distance: {fromAirportData.distance_meters} meters
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSelectAirportFrom}
                          style={{ marginTop: "10px" }}
                        >
                          Select
                        </Button>
                      </Box>
                    )}
                  </DialogContent>
                </Dialog>
                <Tooltip
                  title="Enter the location to search nearby airports"
                  placement="top"
                  arrow
                >
                  <div
                    className="search-field"
                    onClick={() =>
                      setIsToLocationInputVisible(!isToLocationInputVisible)
                    }
                  >
                    {isToLocationInputVisible ? (
                      <Autocomplete
                        onLoad={(autocomplete) =>
                          (autocompleteRef.current = autocomplete)
                        }
                        onPlaceChanged={handleToPlaceChanged}
                      >
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={toLocation}
                          onChange={(e) => {
                            setToLocation(e.target.value);
                          }}
                          autoFocus
                          placeholder="Enter to location"
                        />
                      </Autocomplete>
                    ) : (
                      <>
                        <div className="search-field-label">To</div>
                        <div className="search-field-value">{toLocation}</div>
                      </>
                    )}
                  </div>
                </Tooltip>
                <Dialog
                  open={isToAirportDialogOpen}
                  onClose={() => setIsToAirportDialogOpen(false)}
                >
                  <DialogTitle>Nearby Airport</DialogTitle>
                  <DialogContent>
                    {toAirportData && (
                      <Box>
                        <Typography>
                          Airport Name: {toAirportData.name} (IATA:{" "}
                          {toAirportData.IATA})
                        </Typography>
                        <Typography>
                          Distance: {toAirportData.distance_meters} meters
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSelectAirportTo}
                          style={{ marginTop: "10px" }}
                        >
                          Select
                        </Button>
                      </Box>
                    )}
                  </DialogContent>
                </Dialog>

                <div className="search-field">
                  {isDepartureDateInputVisible ? (
                    <DatePicker
                      label="Departure"
                      fullWidth
                      value={departureDate}
                      onChange={(newDate) => setDepartureDate(newDate)}
                      onAccept={toggleDepartureDateInput}
                      onClose={toggleDepartureDateInput}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  ) : (
                    <>
                      <div
                        className="search-field-label"
                        onClick={toggleDepartureDateInput}
                      >
                        Departure
                      </div>
                      <div
                        className="search-field-value"
                        onClick={toggleDepartureDateInput}
                      >
                        {formatDate(departureDate)}
                      </div>
                      <div
                        className="search-field-label"
                        onClick={toggleDepartureDateInput}
                      >
                        {getWeekDay(departureDate)}
                      </div>
                    </>
                  )}
                </div>
                <div className="search-field">
                  {isReturnDateInputVisible ? (
                    <DatePicker
                      label="Return"
                      fullWidth
                      value={returnDate}
                      onChange={(newDate) => setReturnDate(newDate)}
                      onAccept={toggleReturnDateInput}
                      onClose={toggleReturnDateInput}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  ) : (
                    <>
                      <div
                        className="search-field-label"
                        onClick={toggleReturnDateInput}
                      >
                        Return
                      </div>
                      <div
                        className="search-field-value"
                        onClick={toggleReturnDateInput}
                      >
                        {formatDate(returnDate)}
                      </div>
                      <div
                        className="search-field-label"
                        onClick={toggleReturnDateInput}
                      >
                        {getWeekDay(returnDate)}
                      </div>
                    </>
                  )}
                </div>

                <div className="search-field" onClick={handleGuestsDialogOpen}>
                  <div className="search-field-label">Travelers & Class</div>
                  <div className="search-field-value">
                    {travellers} Travellers, Class
                  </div>
                </div>
              </div>
              <div className="search-button-container">
                <Button
                  className="search-button"
                  variant="contained"
                  color="primary"
                  onClick={handleFlightSearch}
                >
                  Search
                </Button>
              </div>
            </Box>
          )}
        </Box>

        <Dialog open={isGuestsDialogOpen} onClose={handleGuestsDialogClose}>
          <DialogTitle>Travelers & Class</DialogTitle>
          <DialogContent>
            <Box display="flex" alignItems="center" marginBottom={2}>
              <Typography>Travellers</Typography>
              <IconButton onClick={() => setTravellers(travellers + 1)}>
                <KeyboardArrowUp />
              </IconButton>
              <Typography>{travellers}</Typography>
              <IconButton
                onClick={() => setTravellers(Math.max(1, travellers - 1))}
              >
                <KeyboardArrowDown />
              </IconButton>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={updateGuestsCount}>OK</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isChildAgeDialogOpen}
          onClose={() => setIsChildAgeDialogOpen(false)}
        >
          <DialogTitle>Enter Age of the Child</DialogTitle>
          <DialogContent>
            <TextField
              label="Age"
              value={childAge}
              onChange={(e) => setChildAge(e.target.value)}
              type="number"
              fullWidth
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleChildAgeSubmit}>OK</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </LocalizationProvider>
  );
};

export default FlightSearchSection;
