import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Box } from "@mui/material";

const CustomStepIcon = (props) => {
  const { active, completed } = props;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
        borderRadius: "50%",
      }}
    >
      {completed ? (
        <CheckCircleIcon style={{ color: "#0B5ED7" }} />
      ) : active ? (
        <Box
          sx={{
            width: 24,
            height: 24,
            backgroundColor: "#0B5ED7",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: 8,
              height: 8,
              backgroundColor: "#FFFFFF",
              borderRadius: "50%",
            }}
          />
        </Box>
      ) : (
        <RadioButtonUncheckedIcon style={{ color: "#E0E0E0" }} />
      )}
    </Box>
  );
};

export default CustomStepIcon;
