// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/landingpageimage2.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.login-page {
    display: flex;
    height: 100vh;
  }
  
  .login-left {
    width: 60%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
  }
  
  .login-image-overlay {
    position: absolute;
    top: 10px; 
    left: 50%; 
    transform: translateX(-50%); 
    width: 424px; 
    height: 73px; 
    opacity: 0.9; 
  }
  .login-text {
    font-size: 2.5rem;
    text-align: center;
  }
  
  .login-right {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5%;
    background-color: white;
  }
  
  .login-title {
    font-weight: 600;
    text-align: center;
    color: #003366;
    /* margin-bottom: 20px; */
  }
  
  .login-signin {
    text-align: center;
    margin-top: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Authentication/LoginPage1.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,aAAa;EACf;;EAEA;IACE,UAAU;IACV,yDAA2D;IAC3D,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,YAAY;IACZ,YAAY;IACZ,YAAY;EACd;EACA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;EAClB","sourcesContent":["\r\n.login-page {\r\n    display: flex;\r\n    height: 100vh;\r\n  }\r\n  \r\n  .login-left {\r\n    width: 60%;\r\n    background-image: url('../../assets/landingpageimage2.jpg');\r\n    background-size: cover;\r\n    background-position: center;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    color: white;\r\n    position: relative;\r\n  }\r\n  \r\n  .login-image-overlay {\r\n    position: absolute;\r\n    top: 10px; \r\n    left: 50%; \r\n    transform: translateX(-50%); \r\n    width: 424px; \r\n    height: 73px; \r\n    opacity: 0.9; \r\n  }\r\n  .login-text {\r\n    font-size: 2.5rem;\r\n    text-align: center;\r\n  }\r\n  \r\n  .login-right {\r\n    width: 40%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    padding: 0 5%;\r\n    background-color: white;\r\n  }\r\n  \r\n  .login-title {\r\n    font-weight: 600;\r\n    text-align: center;\r\n    color: #003366;\r\n    /* margin-bottom: 20px; */\r\n  }\r\n  \r\n  .login-signin {\r\n    text-align: center;\r\n    margin-top: 20px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
