import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../contexts/AuthProvider";
import {
  Typography,
  Box,
  Tabs,
  Tab,
  Container,
  Button,
  Link,
  capitalize,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as FlightIcon } from "../assets/logo/flight.svg";
import { ReactComponent as HotelIcon } from "../assets/logo/hotel.svg";
import { ReactComponent as DirectionsCarIcon } from "../assets/logo/cab.svg";
import FlightBG from "../assets/logo/flight-bg.svg";
import logo from "../assets/logo/logo512.png";
import LoginDialog from "./LoginDialog"; // Import the LoginDialog component
import "./Header.css";

import FlightContent from "./FlightContent";
import HotelContent from "./HotelContent";
import CabContent from "./CabContent";
import CheckOut from "./RoomCheckOut/CheckOut";

const TabIcon = styled(Tab)(({ theme }) => ({
  "& svg path": {
    transition: "fill 0.3s",
    fill: "#060606",
  },
  "&:hover svg path": {
    fill: "#0054A1",
  },
  "&.Mui-selected svg path": {
    fill: "#0054A1",
  },
}));

const Header = () => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [role, setRole] = useState(""); // Include role management
  const { isLoggedIn, setIsLoggedIn, setRole, handleLogoutClick } =
    useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    if (token) {
      setIsLoggedIn(true);
      // Assume role retrieval logic if stored somewhere
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    console.log(FlightIcon);
    setSelectedTab(newValue);
  };

  const handleLoginClick = () => {
    setLoginDialogOpen(true);
  };

  const handleLogoutClickOnHeader = () => {
    handleLogoutClick();
    navigate("/");
  };

  const handleCloseLoginDialog = () => {
    setLoginDialogOpen(false);
  };
  const handleCheckStatusClick = () => {
    // Navigate to BookingHistory.js
    navigate("/booking-history");
  };

  const tabLabelStyle = {
    fontFamily: "Noto Sans, sans-serif",
    fontWeight: "400",
    fontSize: "20px",
    letterSpacing: "-0.5px",
    textTransform: "capitalize",
  };

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return <FlightContent />;
      case 1:
        return <HotelContent />;
      case 2:
        return <CabContent />;
      case 3:
        return <CheckOut />;
      default:
        return null;
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#0054A1",
          height: "319px",
          width: "100%",
          margin: "0 auto",
          position: "relative",
        }}
      >
        <Container
          sx={{
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "30px 0",
            }}
          >
            <Typography variant="h6" color="inherit">
              <img src={logo} alt="Logo" width="100px" />
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                fontSize: "14px",
                fontFamily: "Noto Sans, sans-serif",
                fontWeight: "300",
              }}
            >
              <Link href="/offers" underline="none" sx={{ color: "#FFFFFF" }}>
                Offers
              </Link>
              <Link
                onClick={handleCheckStatusClick} // Use the navigate function
                underline="none"
                sx={{ color: "#FFFFFF", cursor: "pointer" }} // Set cursor to pointer for better UX
              >
                Check Status
              </Link>
              <Link
                href="/add-members"
                underline="none"
                sx={{ color: "#FFFFFF" }}
              >
                Add Members
              </Link>
              {isLoggedIn ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    padding: "4px 20px !important",
                    fontSize: "14px",
                    fontFamily: "Noto Sans, sans-serif",
                    fontWeight: "300",
                    textTransform: "capitalize",
                    width: "100px",
                  }}
                  onClick={handleLogoutClickOnHeader}
                >
                  Log Out
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    padding: "4px 20px !important",
                    fontSize: "14px",
                    fontFamily: "Noto Sans, sans-serif",
                    fontWeight: "300",
                    textTransform: "capitalize",
                    width: "100px",
                  }}
                  onClick={handleLoginClick}
                >
                  Login
                </Button>
              )}
            </Box>
          </Box>
          <Typography>
            <img className="flight-src" src={FlightBG} alt="Flight" />
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "#FFFFFF",
              textAlign: "center",
              marginTop: { xs: "-50px", md: "-35px" },
            }}
          >
            <Typography
              variant="h6"
              component="span"
              sx={{
                fontFamily: "Noto Sans, sans-serif",
                fontSize: { xs: "24px", md: "64px" },
                lineHeight: { xs: "64px", md: "96px" },
                fontWeight: 300,
                zIndex: "2",
              }}
            >
              One stop for your
            </Typography>
            <Typography
              variant="h3"
              component="span"
              sx={{
                fontFamily: "poppins",
                fontSize: { xs: "48px", md: "64px" },
                lineHeight: { xs: "64px", md: "96px" },
                fontWeight: 500,
              }}
            >
              {" travel & stay"}
            </Typography>
          </Typography>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: "100%",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              position: "relative",
              padding: "5px 0 56px 0",
              marginTop: "-20px",
            }}
          >
            <Tabs
              centered
              value={selectedTab}
              onChange={handleTabChange}
              className="tab-content"
            >
              <TabIcon
                icon={<FlightIcon />}
                label="Flight"
                style={tabLabelStyle}
              />
              <TabIcon
                icon={<HotelIcon />}
                label="Hotels"
                style={tabLabelStyle}
              />
              <TabIcon
                icon={<DirectionsCarIcon />}
                label="Cab"
                style={tabLabelStyle}
              />
            </Tabs>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          width: "100%",
          position: "relative",
        }}
      >
        {/* Render the Login Dialog */}
        <LoginDialog
          open={loginDialogOpen}
          onClose={handleCloseLoginDialog}
          setIsLoggedIn={setIsLoggedIn}
          setRole={setRole}
        />
        {renderContent()}
      </Box>
    </>
  );
};

export default Header;
