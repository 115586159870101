import React from "react";
import { Box, Typography } from "@mui/material";

const CabContent = () => (
  <Box>
    <Typography>Cab content</Typography>
  </Box>
);

export default CabContent;
