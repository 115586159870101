import React, { createContext, useState, useEffect } from "react";
import * as XLSX from "xlsx";

export const AirportContext = createContext();

const AirportProvider = ({ children }) => {
  const [airportList, setAirportList] = useState([]);

  useEffect(() => {
    const loadAirportData = async () => {
      const storedData = localStorage.getItem("airportData");
      if (storedData) {
        // Load data from localStorage if it exists
        setAirportList(JSON.parse(storedData));
      } else {
        try {
          // Fetch and process Excel file
          const response = await fetch("./airportCodes.xlsx");
          const arrayBuffer = await response.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet);

          // Save to localStorage and state
          localStorage.setItem("airportData", JSON.stringify(json));
          console.log("airportList json=", json);
          setAirportList(json);
        } catch (error) {
          console.error("Error loading airport data:", error);
        }
      }
    };

    loadAirportData();
  }, []);

  return (
    <AirportContext.Provider value={{ airportList }}>
      {children}
    </AirportContext.Provider>
  );
};

export default AirportProvider;
