import React, { createContext, useState } from "react";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [role, setRole] = useState([]);
  const handleLogoutClick = () => {
    localStorage.removeItem("jwt");
    setIsLoggedIn(false);
    setRole([]);
    console.log("log out clicked");
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, setIsLoggedIn, role, setRole, handleLogoutClick }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
