import React, { useContext, useState, useEffect } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useLoadScript } from "@react-google-maps/api";
import SearchSection from "./SearchSection";
import MainContent from "./MainContent";
import "./HotelContent.css";
import AuthContext from "../contexts/AuthProvider";

const libraries = ["places"];

const HotelContent = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCfo4qOcLf6qYZYlYSZvm5pLaI3kBNkLa0",
    libraries,
  });

  const [sortOption, setSortOption] = useState("Popular");
  const [searchParams, setSearchParams] = useState({
    location: "Washington",
    checkIn: new Date(new Date().setDate(new Date().getDate() + 2))
      .toISOString()
      .split("T")[0],
    checkOut: new Date(new Date().setDate(new Date().getDate() + 3))
      .toISOString()
      .split("T")[0],
    guests: 1,
    adults: 1,
    children: 0,
    paxes: [],
  });

  const tabLabelStyle = {
    fontFamily: "Noto Sans, sans-serif",
    fontWeight: "400",
    letterSpacing: "-0.5px",
    textTransform: "capitalize",
    color: "#000",
  };

  const handleSortChange = (event, newValue) => {
    setSortOption(newValue);
  };

  const handleSearch = (params) => {
    setSearchParams(params);
  };

  // useEffect(() => {
  //   setSearchParams({
  //     location: "New York",
  //     checkIn: new Date(new Date().setDate(new Date().getDate() + 2))
  //       .toISOString()
  //       .split("T")[0],
  //     checkOut: new Date(new Date().setDate(new Date().getDate() + 3))
  //       .toISOString()
  //       .split("T")[0],
  //     guests: 1,
  //   });
  // }, []);

  useEffect(() => {
    if (searchParams) {
      console.log("Updated searchParams:", searchParams);
    }
  }, [searchParams]);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          margin: "0 auto",
        }}
      >
        <SearchSection onSearch={handleSearch} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "self-start",
          alignItems: "center",
          width: "100%",
          padding: "10px",
        }}
      >
        <div className="sort">
          <div className="sort-container">
            <label style={tabLabelStyle}>Sort By :</label>
          </div>
          <div className="tab-container">
            <Tabs
              value={sortOption}
              onChange={handleSortChange}
              aria-label="sort options"
            >
              <Tab label="Popular" style={tabLabelStyle} value="Popular" />
              <Tab label="Budget" style={tabLabelStyle} value="Budget" />
              <Tab label="Premium" style={tabLabelStyle} value="Premium" />
              <Tab
                label="Price (Highest)"
                style={tabLabelStyle}
                value="PriceHighest"
              />
              <Tab label="Ratings" style={tabLabelStyle} value="Ratings" />
            </Tabs>
          </div>
        </div>
      </Box>
      {searchParams && (
        <MainContent
          // key={JSON.stringify(searchParams)}
          sortOption={sortOption}
          searchParams={searchParams}
          // isLoggedIn={isLoggedIn}
          // handleLogoutClick={handleLogoutClick}
        />
      )}
    </Box>
  );
};

export default HotelContent;
