import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import FlightImage from "../../assets/flight.png";
import logo from "../../assets/logo/logoSignUp.png";
import AuthContext from "../../contexts/AuthProvider";
import "./LoginPage1.css";

const LoginPage1 = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const { handleLogoutClick } = useContext(AuthContext);

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError("");
    console.log("password changed to", password);
    try {
      const token = localStorage.getItem("jwt");
      await axios.post(
        "http://104.237.9.131:8080/api/userauth/changePassword/FirstLogin",
        { password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleLogoutClick();
      navigate("/loginActivated2"); // Redirect to a dashboard or login page after password is set
    } catch (error) {
      setError("Failed to set password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page">
      <Box className="login-left">
        <img src={FlightImage} alt="Flight" className="login-image-overlay" />
      </Box>
      <Box className="login-right">
        <Typography sx={{ margin: "5%" }} variant="h6" className="login-title">
          <img src={logo} alt="Logo" width="55%" />
        </Typography>
        <Typography variant="h5">Set Your New Password</Typography>
        <TextField
          label="New Password"
          variant="outlined"
          fullWidth
          margin="normal"
          type="password"
          value={password}
          onChange={handleChange}
        />
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Set Password"
          )}
        </Button>
      </Box>
    </div>
  );
};

export default LoginPage1;
