// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-bar {
  background-color: #24A0ED;
  /* height: 319px; */
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-bar .container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-bar .container .links {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.header-bar .container img {
  /* width: 424px; */
  width: 20%;
  /* height: 73px; */
  height: auto;
  opacity: 0.9;
}

.header-bar .container h3 {
  color: #FFFFFF;
  font-size: 64px;
  line-height: 96px;
  font-weight: 275;
  margin-top: -75px;
}

.header-bar .container .tabs-container {
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 8px;
  position: absolute;
  bottom: 0;
  padding: 10px 0;
}

.flight-src {
  margin-top: -20px;
  margin-left: -240px;

}

.tab-content label{
  font-family: 'Noto Sans', sans-serif;
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;;AAErB;;AAEA;EACE,oCAAoC;EACpC,0BAA0B;AAC5B","sourcesContent":[".header-bar {\n  background-color: #24A0ED;\n  /* height: 319px; */\n  height: 40vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.header-bar .container {\n  position: relative;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.header-bar .container .links {\n  display: flex;\n  gap: 20px;\n  margin-bottom: 10px;\n}\n\n.header-bar .container img {\n  /* width: 424px; */\n  width: 20%;\n  /* height: 73px; */\n  height: auto;\n  opacity: 0.9;\n}\n\n.header-bar .container h3 {\n  color: #FFFFFF;\n  font-size: 64px;\n  line-height: 96px;\n  font-weight: 275;\n  margin-top: -75px;\n}\n\n.header-bar .container .tabs-container {\n  background-color: #FFFFFF;\n  width: 100%;\n  border-radius: 8px;\n  position: absolute;\n  bottom: 0;\n  padding: 10px 0;\n}\n\n.flight-src {\n  margin-top: -20px;\n  margin-left: -240px;\n\n}\n\n.tab-content label{\n  font-family: 'Noto Sans', sans-serif;\n  text-transform: capitalize;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
