import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../contexts/AuthProvider";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  IconButton,
  Button,
  Menu,
  MenuItem,
  TextField,
  Select,
  FormControl,
  InputLabel,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PeopleIcon from "@mui/icons-material/People";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AnalyticsIcon from "@mui/icons-material/BarChart";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import profileImage from "../assets/profileImage.png";

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Microsoft JhengHei UI",
  fontWeight: 700,
  fontSize: "24px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
}));

const FilterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#007bff",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#0056b3",
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

const Sidebar = () => {
  const navigate = useNavigate();

  const menuItems = [
    { text: "Dashboard", icon: <DashboardIcon />, path: "/AdminDashBoard" },
    {
      text: "Activate Sign up",
      icon: <GroupAddIcon />,
      path: "/activate-signup",
    },
    { text: "Active Clients", icon: <PeopleIcon />, path: "/active-clients" },
    {
      text: "Discount Coupons",
      icon: <LocalOfferIcon />,
      path: "/discount-coupons",
    },
  ];

  return (
    <Box
      sx={{
        width: 240,
        height: "100vh",
        backgroundColor: "#FFFFFF",
        color: "#000",
        display: "flex",
        flexDirection: "column",
        paddingTop: 2,
      }}
    >
      <Box
        sx={{
          paddingLeft: 2,
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <AnalyticsIcon sx={{ color: "#000", marginRight: 1 }} />
        <Box>
          <Typography
            variant="h6"
            noWrap
            sx={{
              fontFamily: "Microsoft JhengHei UI",
              fontWeight: 700,
              color: "#000000",
            }}
          >
            Control Panel
          </Typography>
          <Typography
            variant="subtitle1"
            noWrap
            sx={{
              fontFamily: "Microsoft JhengHei UI",
              fontWeight: 400,
              color: "#555555",
            }}
          >
            Analytics
          </Typography>
        </Box>
      </Box>
      <Box sx={{ paddingLeft: 2, paddingTop: 2 }}>
        {menuItems.map((item) => (
          <Box
            key={item.text}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px 15px",
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor:
                item.path === window.location.pathname
                  ? "#0054A1"
                  : "transparent",
              "&:hover": {
                backgroundColor: "#0054A1",
                "& .MuiTypography-root": {
                  color: "#FFFFFF",
                },
                "& .MuiSvgIcon-root": {
                  color: "#FFFFFF",
                },
              },
            }}
            onClick={() => navigate(item.path)}
          >
            <Box
              sx={{
                color:
                  item.path === window.location.pathname ? "#FFFFFF" : "#000",
              }}
            >
              {item.icon}
            </Box>
            <Typography
              variant="body1"
              sx={{
                marginLeft: "10px",
                color:
                  item.path === window.location.pathname ? "#FFFFFF" : "#000",
                fontFamily: "Microsoft JhengHei UI",
                fontWeight: 500,
              }}
            >
              {item.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const ActivateSignUp = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
  const { handleLogoutClick } = useContext(AuthContext);
  const navigate = useNavigate();

  const openMenu = (event, userId) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(userId);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedUserId(null);
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch(
        `http://104.237.9.131:8080/api/admin/panel/users/not-activated?page=${page}&size=${rowsPerPage}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setUsers(data.content);
        setTotalElements(data.totalElements);
      } else {
        console.error("Failed to fetch users.");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage]);

  const handleAction = async (action) => {
    const activeOrReject = action === "Activate";
    console.log("In handleAction activeOrReject=", activeOrReject);
    try {
      const response = await fetch(
        `http://104.237.9.131:8080/api/admin/panel/users/action?userId=${selectedUserId}&activeOrReject=${activeOrReject}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      if (response.ok) {
        closeMenu();
        fetchUsers();
      } else {
        console.error("Failed to perform action.");
      }
    } catch (error) {
      console.error("Error performing action:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((user) => user.userId);
      setSelectedUsers(newSelecteds);
      return;
    }
    setSelectedUsers([]);
  };

  const handleCheckboxClick = (event, userId) => {
    const selectedIndex = selectedUsers.indexOf(userId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, userId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelected);
  };

  const isSelected = (userId) => selectedUsers.indexOf(userId) !== -1;

  const handleProfileClick = (event) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    handleLogoutClick();
    navigate("/");
  };

  return (
    <Box display="flex">
      <Sidebar />
      <Box flexGrow={1} p={3}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <StyledTypography variant="h4">
            <AssignmentTurnedInIcon fontSize="large" />
            Activate Sign Up
          </StyledTypography>
          <Box display="flex" alignItems="center">
            <TextField variant="outlined" placeholder="Search" size="small" />
            <IconButton>
              <NotificationsIcon />
            </IconButton>
            <IconButton onClick={handleProfileClick}>
              <Avatar src={profileImage} alt="Profile" />
            </IconButton>
            <Menu
              anchorEl={profileMenuAnchorEl}
              open={Boolean(profileMenuAnchorEl)}
              onClose={() => setProfileMenuAnchorEl(null)}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Box>
        <FilterBox>
          <Button
            variant="outlined"
            onClick={handleSelectAllClick}
            sx={{ textTransform: "none" }}
          >
            All
          </Button>
          <FormControl variant="outlined" style={{ minWidth: 120 }}>
            <InputLabel>City</InputLabel>
            <Select label="City">
              {/* City Filter Options to be implemented later */}
            </Select>
          </FormControl>
          <TextField label="Search" variant="outlined" />
          <StyledButton variant="contained" color="primary">
            Approve
          </StyledButton>
        </FilterBox>
        <StyledTableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedUsers.length > 0 &&
                      selectedUsers.length < users.length
                    }
                    checked={
                      users.length > 0 && selectedUsers.length === users.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Corporate Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Person Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Email ID</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Phone</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>City</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Signup Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                const isItemSelected = isSelected(user.userId);
                return (
                  <TableRow
                    key={user.userId}
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onChange={(event) =>
                          handleCheckboxClick(event, user.userId)
                        }
                      />
                    </TableCell>
                    <TableCell>{user.corporateName || "-"}</TableCell>
                    <TableCell>{user.personName || "-"}</TableCell>
                    <TableCell>{user.emailId}</TableCell>
                    <TableCell>{user.phoneNumber || "-"}</TableCell>
                    <TableCell>{user.city || "-"}</TableCell>
                    <TableCell>
                      {new Date(user.createdTS).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(event) => openMenu(event, user.userId)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={
                          Boolean(anchorEl) && selectedUserId === user.userId
                        }
                        onClose={closeMenu}
                      >
                        <MenuItem onClick={() => handleAction("Activate")}>
                          Activate
                        </MenuItem>
                        <MenuItem onClick={() => handleAction("Reject")}>
                          Reject
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Box display="flex" justifyContent="center" m={2}>
            <Pagination
              count={Math.ceil(totalElements / rowsPerPage)}
              page={page + 1}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              renderItem={(item) => (
                <PaginationItem {...item} style={{ margin: "0 4px" }} />
              )}
            />
          </Box>
        </StyledTableContainer>
      </Box>
    </Box>
  );
};

export default ActivateSignUp;
