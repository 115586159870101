import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Tooltip,
  Box,
  Rating,
} from "@mui/material";
import dummyImage from "../assets/dummyHotelSearchCard.jpg";
import "./HotelCard.css";

const HotelCard = ({ hotel, onCardClick }) => {
  const rating = extractRating(hotel.categoryName);
  const imageUrl =
    hotel.hotelGeneralViewImagePaths &&
    hotel.hotelGeneralViewImagePaths.length > 0
      ? `https://photos.hotelbeds.com/giata/bigger/${hotel.hotelGeneralViewImagePaths[0]}`
      : dummyImage;

  return (
    <Card className="hotel-card" onClick={() => onCardClick(hotel)}>
      <CardMedia
        component="img"
        height="180"
        image={imageUrl}
        alt={hotel.name}
      />
      <CardContent className="card-content">
        <Tooltip title={hotel.name} arrow>
          <Typography variant="h6" component="div" className="hotel-name">
            {hotel.name}
          </Typography>
        </Tooltip>
        <Typography
          variant="body2"
          className="hotel-location"
          color="text.secondary"
        >
          {hotel.destinationName}
        </Typography>
        <Box display="flex" alignItems="center" mb={1}>
          <Rating
            name="read-only"
            className="hotel-rating"
            value={rating}
            precision={0.1}
            readOnly
          />
          <Typography
            variant="body2"
            color="text.secondary"
            className="rating-text"
          >
            {rating.toFixed(1)} ({hotel.reviewsCount || 0} reviews)
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
            className="discount"
          >
            {hotel.discount ? `${hotel.discount}% off` : "30% off"}
          </Typography>
        </Box>
        <Box className="book-container">
          <Typography variant="body1" color="text.primary">
            <span className="rate-content">${hotel.minRate}</span>
            <span className="date-time">/night</span>
          </Typography>
          <Typography>
            <Button size="small" className="book-button">
              Book
            </Button>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export const extractRating = (categoryName) => {
  const match = categoryName.match(/(\d+(\.\d+)?)/);
  let rating = match ? parseFloat(match[0]) : 0;

  if (categoryName.toUpperCase().includes("AND A HALF")) {
    rating += 0.5;
  }

  return rating;
};

export default HotelCard;
