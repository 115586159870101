import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import axios from "axios";
import TopHeader from "./TopHeader";
import BookingDialog from "./FlightCheckOut/BookingDialog";

const BookingHistory = () => {
  const [hotelbookings, setHotelBookings] = useState([]);
  const [flightbookings, setFlightBookings] = useState([]);
  // const [flightSegments, setFlightSegments] = useState([]);
  const [loading, setLoading] = useState(true); // State to handle loading
  const [selectedFlightSegments, setSelectedFlightSegments] = useState([]);
  const [bookingDialogOpen, setBookingDialogOpen] = useState(false);
  const [selectedFlightBookingId, setSelectedFlightBookingId] = useState(null);

  useEffect(() => {
    const fetchHotelBookings = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `http://104.237.9.131:8080/api/accommodation/booking/pastBookingList/${localStorage.getItem(
            "username"
          )}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setHotelBookings(response.data);
      } catch (error) {
        console.error("Error fetching booking history:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchFlightBookings = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          `http://104.237.9.131:8080/api/flight/mystiFly/pastBookingList/${localStorage.getItem(
            "username"
          )}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        const updatedFlightBookings = response.data.map((booking) => {
          let parsedSegments = [];
          try {
            if (booking.flightSegments) {
              parsedSegments = JSON.parse(booking.flightSegments);
            }
          } catch (error) {
            console.error(
              "Error parsing flightSegments for booking:",
              booking.id,
              error
            );
          }

          return {
            ...booking,
            flightSegments: parsedSegments,
          };
        });

        setFlightBookings(updatedFlightBookings);
        console.log("Updated Flight Bookings:", updatedFlightBookings);
      } catch (error) {
        console.error("Error fetching flight booking history:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHotelBookings();
    fetchFlightBookings();
  }, []);

  const handleViewDetails = async (bookingId) => {
    try {
      const response = await axios.get(
        `http://104.237.9.131:8080/api/accommodation/booking/generateVoucher`,
        {
          params: { bookingId },
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `BookingVoucher_${bookingId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error generating booking voucher PDF:", error);
    }
  };

  const handleFlightViewDetails = (flightSegments, flightBookingId) => {
    console.log(
      "handleFlightViewDetails called, flightSegments=",
      flightSegments
    );
    console.log("flightBookingId=", flightBookingId);
    setSelectedFlightSegments(flightSegments);
    setSelectedFlightBookingId(flightBookingId);
    setBookingDialogOpen(true);
  };

  const handleCloseBookingDialog = () => {
    setBookingDialogOpen(false);
  };

  return (
    <>
      <TopHeader />
      <Container>
        <Box mt={4} mb={4}>
          <Typography variant="h4" gutterBottom>
            Booking History
          </Typography>
          {loading ? (
            <Box display="flex" justifyContent="center" mt={4}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    <strong>Hotel Booking History</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Booking ID</TableCell>
                          <TableCell>Hotel Name</TableCell>
                          <TableCell>Check-in Date</TableCell>
                          <TableCell>Check-out Date</TableCell>
                          <TableCell>Total Amount</TableCell>
                          <TableCell>Currency</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {hotelbookings.length > 0 ? (
                          hotelbookings.map((booking) => (
                            <TableRow key={booking.id}>
                              <TableCell>{booking.bookingId}</TableCell>
                              <TableCell>{booking.hotelName}</TableCell>
                              <TableCell>
                                {dayjs(booking.checkinDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </TableCell>
                              <TableCell>
                                {dayjs(booking.checkoutDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </TableCell>
                              <TableCell>{`${booking.totalRoomRent}`}</TableCell>
                              <TableCell>{booking.currency}</TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    handleViewDetails(booking.bookingId)
                                  }
                                >
                                  Download voucher
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              No hotelbookings found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h6">
                    <strong>Flight Booking History</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Booking MF Reference</TableCell>
                          <TableCell>BookedOn</TableCell>
                          <TableCell>TripType</TableCell>
                          <TableCell>Origin</TableCell>
                          <TableCell>Destination</TableCell>
                          <TableCell>Total Fare</TableCell>
                          <TableCell>Currency</TableCell>
                          <TableCell>DepartureDateTime</TableCell>
                          <TableCell>ArrivalDateTime</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {flightbookings.length > 0 ? (
                          flightbookings.map((booking) => (
                            <TableRow key={booking.uniqueId}>
                              <TableCell>{booking.uniqueId}</TableCell>
                              <TableCell>
                                {dayjs(booking.createdTs).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </TableCell>
                              <TableCell>{booking.tripType}</TableCell>
                              <TableCell>{booking.origin}</TableCell>
                              <TableCell>{booking.destination}</TableCell>
                              <TableCell>{booking.totalFare}</TableCell>
                              <TableCell>{booking.currency}</TableCell>
                              <TableCell>
                                {dayjs(booking.departureDate).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </TableCell>
                              <TableCell>
                                {dayjs(booking.arrivalDate).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    handleFlightViewDetails(
                                      booking.flightSegments,
                                      booking.uniqueId
                                    )
                                  }
                                >
                                  View/Download Voucher
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              No flightbookings found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              <BookingDialog
                bookingDialogOpen={bookingDialogOpen}
                handleCloseBookingDialog={handleCloseBookingDialog}
                bookingReference={selectedFlightBookingId}
                flightSegments={selectedFlightSegments}
              />
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default BookingHistory;
