import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  MenuItem,
  CircularProgress,
} from "@mui/material";

const SignUpDialog = ({ open, onClose, onSignUpSuccess, buttonStyle }) => {
  const [userType, setUserType] = useState("Corporate");
  const [formData, setFormData] = useState({});
  const [signUpData, setSignUpData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const roleMap = {
      Admin: "ROLE_ADMIN",
      Corporate: "ROLE_CORPORATE",
      Employee: "ROLE_EMPLOYEE",
    };

    const corporateData =
      userType === "Corporate"
        ? {
            corporateName: formData.corporate_name,
            personName: formData.person_name,
            city: formData.city,
            phoneNumber: formData.phone_number,
          }
        : {};

    setSignUpData({
      username: formData.email_id,
      password: formData.password,
      role: roleMap[userType],
      ...corporateData,
    });
  }, [userType, formData]);

  const handleSignUp = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "http://104.237.9.131:8080/api/userauth/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(signUpData),
        }
      );

      if (response.ok) {
        onSignUpSuccess();
      } else {
        // Handle error
      }
    } catch (error) {
      console.error("Sign up failed", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Sign Up</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            select
            fullWidth
            label="User Type"
            name="user_type"
            value={userType}
            onChange={(e) => {
              setUserType(e.target.value);
              handleInputChange(e);
            }}
            margin="normal"
          >
            <MenuItem value="Corporate">Corporate</MenuItem>
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="Employee">Employee</MenuItem>
          </TextField>

          <TextField
            margin="normal"
            required
            fullWidth
            label="Email Id"
            name="email_id"
            helperText="This Email id can be used as username for login"
            onChange={handleInputChange}
          />

          {userType === "Corporate" ? (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Corporate Name"
                name="corporate_name"
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Person Name"
                name="person_name"
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                fullWidth
                label="City"
                name="city"
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Phone Number"
                name="phone_number"
                onChange={handleInputChange}
              />
            </>
          ) : (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                label="First Name"
                name="first_name"
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Middle Name"
                name="middle_name"
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Last Name"
                name="last_name"
                onChange={handleInputChange}
              />
            </>
          )}

          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"
            name="password"
            type="password"
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Confirm Password"
            name="confirm_password"
            type="password"
            onChange={handleInputChange}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Button onClick={handleSignUp} style={buttonStyle}>
            Sign Up
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SignUpDialog;
