import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import flightAirlineImg from "../assets/flightAirline.png";
import vectorImage from "../assets/vectorImage.png";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Card,
  CardContent,
  Divider,
  Slider,
} from "@mui/material";
import { Columns } from "lucide-react";
import planeImage from "../assets/greyPlaneImage.png";
import TopHeader from "./TopHeader";

const FlightResults = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { flightSearchParams } = location.state || {};
  const [searchResponse, setSearchResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    nonStop: false,
    oneStop: false,
    twoPlusStops: false,
    airlines: [],
    departureTimeRange: [0, 1440], // Full day in minutes
    arrivalTimeRanges: {}, // Dynamic range for each arrival date
  });
  const [airlineOptions, setAirlineOptions] = useState([]);
  const [arrivalDates, setArrivalDates] = useState([]);
  const [stopFares, setStopFares] = useState({});
  const [airlineFares, setAirlineFares] = useState({});

  useEffect(() => {
    if (flightSearchParams) {
      const fetchData = async () => {
        setLoading(true);
        const token = localStorage.getItem("jwt");

        try {
          const originDestinationInfo = [
            {
              DepartureDateTime: `${flightSearchParams.departureDate}T00:00:00`,
              OriginLocationCode: flightSearchParams.fromAirportData.IATA,
              DestinationLocationCode: flightSearchParams.toAirportData.IATA,
            },
          ];

          // Add the return flight details if tripType is "Return"
          if (flightSearchParams.tripType === "Return") {
            originDestinationInfo.push({
              DepartureDateTime: `${flightSearchParams.returnDate}T00:00:00`,
              OriginLocationCode: flightSearchParams.toAirportData.IATA,
              DestinationLocationCode: flightSearchParams.fromAirportData.IATA,
            });
          }
          const response = await axios.post(
            "http://104.237.9.131:8080/api/flight/mystiFly/search",
            {
              OriginDestinationInformations: originDestinationInfo,
              TravelPreferences: {
                MaxStopsQuantity: "All",
                CabinPreference: "Y",
                Preferences: {
                  CabinClassPreference: {
                    CabinType: "Y",
                    PreferenceLevel: "Preferred",
                  },
                },
                AirTripType: flightSearchParams.tripType,
              },
              PricingSourceType: "All",
              IsRefundable: false,
              PassengerTypeQuantities: [
                {
                  Code: "ADT",
                  Quantity: flightSearchParams.adults,
                },
              ],
              RequestOptions: "Fifty",
              Target: "Test",
              ConversationId: "A123456",
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          setSearchResponse(response.data);
          const stopsFares = {
            Nonstop: Infinity,
            "1 Stop": Infinity,
            "2+ Stops": Infinity,
          };
          const stopsCurrencies = {};
          const airlineFaresTemp = {};
          const airlinesSet = new Set();
          const arrivalDatesSet = new Set();

          response.data.Data.PricedItineraries.forEach((itinerary) => {
            const fare =
              itinerary.AirItineraryPricingInfo.ItinTotalFare.TotalFare.Amount;
            const currency =
              itinerary.AirItineraryPricingInfo.ItinTotalFare.TotalFare
                .CurrencyCode;

            const stops =
              flightSearchParams.tripType === "Return"
                ? Math.max(
                    itinerary.OriginDestinationOptions[0].FlightSegments
                      .length - 1,
                    itinerary.OriginDestinationOptions[1].FlightSegments
                      .length - 1
                  )
                : itinerary.OriginDestinationOptions[0].FlightSegments.length -
                  1;
            // if tripType === "Return", then
            // Math.max()
            if (stops === 0 && fare < stopsFares.Nonstop) {
              stopsFares.Nonstop = fare;
              stopsCurrencies.Nonstop = currency;
            }
            if (stops === 1 && fare < stopsFares["1 Stop"]) {
              stopsFares["1 Stop"] = fare;
              stopsCurrencies["1 Stop"] = currency;
            }
            if (stops >= 2 && fare < stopsFares["2+ Stops"]) {
              stopsFares["2+ Stops"] = fare;
              stopsCurrencies["2+ Stops"] = currency;
            }

            itinerary.OriginDestinationOptions.forEach((option) =>
              option.FlightSegments.forEach((segment) => {
                airlinesSet.add(segment.OperatingAirline.airlineName);
                arrivalDatesSet.add(
                  new Date(segment.ArrivalDateTime).toISOString().split("T")[0]
                );
                const airlineName = segment.OperatingAirline.airlineName;
                if (!airlineFaresTemp[airlineName])
                  airlineFaresTemp[airlineName] = { fare: Infinity, currency };
                if (fare < airlineFaresTemp[airlineName].fare) {
                  airlineFaresTemp[airlineName] = { fare, currency };
                }
              })
            );
          });

          setStopFares({
            Nonstop:
              stopsFares.Nonstop === Infinity
                ? null
                : `${stopsCurrencies.Nonstop} ${stopsFares.Nonstop}`,
            "1 Stop":
              stopsFares["1 Stop"] === Infinity
                ? null
                : `${stopsCurrencies["1 Stop"]} ${stopsFares["1 Stop"]}`,
            "2+ Stops":
              stopsFares["2+ Stops"] === Infinity
                ? null
                : `${stopsCurrencies["2+ Stops"]} ${stopsFares["2+ Stops"]}`,
          });
          setAirlineFares(
            Object.fromEntries(
              Object.entries(airlineFaresTemp).map(([airline, data]) => [
                airline,
                `${data.currency} ${data.fare} `,
              ])
            )
          );
          setAirlineOptions([...airlinesSet]);
          setArrivalDates([...arrivalDatesSet]);
          console.log("response.data", response.data);
        } catch (error) {
          console.error("Error fetching flight data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [flightSearchParams]);

  const handleFlightBookClick = (flight) => {
    navigate("/flight-checkout", {
      state: {
        flight,
        flightSearchParams,
      },
    });
  };
  const handleSliderChange = (type, value, date = null) => {
    setFilters((prevFilters) => {
      if (type === "departureTime") {
        return { ...prevFilters, departureTimeRange: value };
      } else {
        return {
          ...prevFilters,
          arrivalTimeRanges: {
            ...prevFilters.arrivalTimeRanges,
            [date]: value,
          },
        };
      }
    });
  };
  const handleCheckboxChange = (filterName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
  };
  const handleAirlineFilterChange = (airline) => {
    setFilters((prevFilters) => {
      const updatedAirlines = prevFilters.airlines.includes(airline)
        ? prevFilters.airlines.filter((a) => a !== airline)
        : [...prevFilters.airlines, airline];
      return { ...prevFilters, airlines: updatedAirlines };
    });
  };
  const calculateTotalJourneyDuration = (segment) => {
    const departureTime = new Date(segment.DepartureDateTime);
    const arrivalTime = new Date(segment.ArrivalDateTime);
    const segmentDuration = (arrivalTime - departureTime) / (1000 * 60); // Convert milliseconds to minutes
    return segmentDuration;
  };
  const formatDuration = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  const constructAirlinesForSegments = (segments) => {
    const reducedString = segments.reduce((airlines, segment) => {
      const segmentAirline =
        segment.OperatingAirline.Code +
        "-" +
        segment.OperatingAirline.FlightNumber +
        "(" +
        segment.OperatingAirline.airlineName +
        ")" +
        ",";
      return segmentAirline + airlines;
    }, "");
    if (reducedString.endsWith(",")) {
      return reducedString.slice(0, -1);
    }
    return reducedString;
  };
  const filteredFlights = () => {
    if (!searchResponse || !searchResponse.Data.PricedItineraries) return [];
    const {
      nonStop,
      oneStop,
      twoPlusStops,
      airlines,
      departureTimeRange,
      arrivalTimeRanges,
    } = filters;

    if (
      !nonStop &&
      !oneStop &&
      !twoPlusStops &&
      airlines.length === 0 &&
      Object.keys(arrivalTimeRanges).length === 0 &&
      departureTimeRange[0] === 0 &&
      departureTimeRange[1] === 1440
    ) {
      return searchResponse.Data.PricedItineraries;
    }

    return searchResponse.Data.PricedItineraries.filter((flight) => {
      const stops =
        flightSearchParams.tripType === "Return"
          ? Math.max(
              flight.OriginDestinationOptions[0].FlightSegments.length - 1,
              flight.OriginDestinationOptions[1].FlightSegments.length - 1
            )
          : flight.OriginDestinationOptions[0].FlightSegments.length - 1;

      const matchStops =
        (!nonStop && !oneStop && !twoPlusStops) ||
        (nonStop && stops === 0) ||
        (oneStop && stops === 1) ||
        (twoPlusStops && stops >= 2);

      const matchAirlines =
        airlines.length === 0 ||
        flight.OriginDestinationOptions.some((option) =>
          option.FlightSegments.some((segment) =>
            airlines.includes(segment.OperatingAirline.airlineName)
          )
        );

      const matchDepartureTime =
        flight.OriginDestinationOptions[0].FlightSegments.every((segment) => {
          const departureMinutes =
            new Date(segment.DepartureDateTime).getHours() * 60 +
            new Date(segment.DepartureDateTime).getMinutes();
          return (
            departureMinutes >= departureTimeRange[0] &&
            departureMinutes <= departureTimeRange[1]
          );
        });

      const matchArrivalTimes = flight.OriginDestinationOptions.every(
        (option) =>
          option.FlightSegments.every((segment) => {
            const arrivalDate = new Date(segment.ArrivalDateTime)
              .toISOString()
              .split("T")[0];
            const arrivalMinutes =
              new Date(segment.ArrivalDateTime).getHours() * 60 +
              new Date(segment.ArrivalDateTime).getMinutes();

            return (
              !arrivalTimeRanges[arrivalDate] ||
              (arrivalMinutes >= arrivalTimeRanges[arrivalDate][0] &&
                arrivalMinutes <= arrivalTimeRanges[arrivalDate][1])
            );
          })
      );

      return (
        matchStops && matchAirlines && matchDepartureTime && matchArrivalTimes
      );
    });
  };

  return (
    <>
      <TopHeader />

      <Box
        display="flex"
        alignItems="self-start"
        justifyContent="space-around"
        margin="1%"
      >
        {/* Left Box for Filters */}
        <Card
          width="25%"
          // margin="2%"
          padding="1%"
          border="1px solid #ddd"
          borderRadius="8px"
          // height="auto"
        >
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Filter By:
            </Typography>
            {/* Add filtering options here */}
            <Typography variant="subtitle2">
              <strong>Number of Stops</strong>
            </Typography>
            <Box>
              <label>
                <input
                  type="checkbox"
                  checked={filters.nonStop}
                  onChange={() => handleCheckboxChange("nonStop")}
                />{" "}
                Nonstop{" "}
                {stopFares.Nonstop && (
                  <Typography component="span">
                    (starts from {stopFares.Nonstop})
                  </Typography>
                )}
              </label>
            </Box>
            <Box>
              <label>
                <input
                  type="checkbox"
                  checked={filters.oneStop}
                  onChange={() => handleCheckboxChange("oneStop")}
                />{" "}
                1 Stop{" "}
                {stopFares["1 Stop"] && (
                  <Typography component="span">
                    (starts from {stopFares["1 Stop"]})
                  </Typography>
                )}
              </label>
            </Box>
            <Box>
              <label>
                <input
                  type="checkbox"
                  checked={filters.twoPlusStops}
                  onChange={() => handleCheckboxChange("twoPlusStops")}
                />{" "}
                2+ Stops{" "}
                {stopFares["2+ Stops"] && (
                  <Typography component="span">
                    (starts from {stopFares["2+ Stops"]})
                  </Typography>
                )}
              </label>
            </Box>
            <Divider sx={{ margin: "2%", width: "100%", opacity: 2.0 }} />
            <Typography variant="subtitle2" marginTop="16px">
              <strong>Airlines</strong>
            </Typography>
            {airlineOptions.map((airline) => (
              <Box key={airline}>
                <label>
                  <input
                    type="checkbox"
                    checked={filters.airlines.includes(airline)}
                    onChange={() => handleAirlineFilterChange(airline)}
                  />{" "}
                  {airline}{" "}
                  {airlineFares[airline] && (
                    <Typography component="span">
                      (starts from {airlineFares[airline]})
                    </Typography>
                  )}
                </label>
              </Box>
            ))}
            <Divider sx={{ margin: "2%", width: "100%", opacity: 2.0 }} />
            <Typography variant="subtitle2" marginTop="16px">
              <strong>Travel and Baggage</strong>
            </Typography>
            <Box>
              <label>
                <input type="checkbox" /> Carry-on bag
              </label>
            </Box>
            <Box>
              <label>
                <input type="checkbox" /> Checked bag
              </label>
            </Box>
            {/* Departure Time */}
            <Divider sx={{ margin: "2%", width: "100%", opacity: 2.0 }} />
            <Typography variant="subtitle2">
              <strong>Departure Time</strong>
            </Typography>
            <Box sx={{ marginTop: 1 }}>
              <Typography variant="body2" sx={{ marginBottom: 1 }}>
                {new Intl.DateTimeFormat("en-US", {
                  weekday: "short",
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                }).format(new Date(flightSearchParams.departureDate))}
              </Typography>
              <Slider
                value={filters.departureTimeRange}
                onChange={(e, value) =>
                  handleSliderChange("departureTime", value)
                }
                valueLabelDisplay="auto"
                min={0}
                max={1440}
                sx={{ width: "100%" }}
              />
              <Typography variant="body2" color="textSecondary">
                {`${Math.floor(filters.departureTimeRange[0] / 60)
                  .toString()
                  .padStart(2, "0")}:${(filters.departureTimeRange[0] % 60)
                  .toString()
                  .padStart(2, "0")} - ${Math.floor(
                  filters.departureTimeRange[1] / 60
                )
                  .toString()
                  .padStart(2, "0")}:${(filters.departureTimeRange[1] % 60)
                  .toString()
                  .padStart(2, "0")}`}
              </Typography>
            </Box>

            {/* Arrival Time Sliders */}
            <Divider sx={{ margin: "2%", width: "100%", opacity: 2.0 }} />
            <Typography variant="subtitle2">
              <strong>Arrival Time</strong>
            </Typography>
            {arrivalDates.map((date) => (
              <Box key={date} sx={{ marginBottom: 3 }}>
                {/* <Typography variant="body2" sx={{ marginBottom: 1 }}>
                {date}
              </Typography> */}
                <Typography variant="body2" sx={{ marginBottom: 1 }}>
                  {new Intl.DateTimeFormat("en-US", {
                    weekday: "short",
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  }).format(new Date(date))}
                </Typography>
                <Slider
                  value={filters.arrivalTimeRanges[date] || [0, 1440]}
                  onChange={(e, value) =>
                    handleSliderChange("arrivalTime", value, date)
                  }
                  valueLabelDisplay="auto"
                  min={0}
                  max={1440}
                  sx={{ width: "100%" }}
                />
                <Typography variant="body2" color="textSecondary">
                  {`${Math.floor(
                    (filters.arrivalTimeRanges[date]?.[0] || 0) / 60
                  )
                    .toString()
                    .padStart(2, "0")}:${(
                    (filters.arrivalTimeRanges[date]?.[0] || 0) % 60
                  )
                    .toString()
                    .padStart(2, "0")} - ${Math.floor(
                    (filters.arrivalTimeRanges[date]?.[1] || 1440) / 60
                  )
                    .toString()
                    .padStart(2, "0")}:${(
                    (filters.arrivalTimeRanges[date]?.[1] || 1440) % 60
                  )
                    .toString()
                    .padStart(2, "0")}`}
                </Typography>
              </Box>
            ))}
            <Box
              display="flex"
              justifyContent="space-between"
              // alignItems="center"
              sx={{ marginTop: "16px" }}
            >
              {/* Reset Button */}
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  // Reset all filters to default values
                  setFilters({
                    nonStop: false,
                    oneStop: false,
                    twoPlusStops: false,
                    airlines: [],
                    departureTimeRange: [0, 1440],
                    arrivalTimeRanges: {},
                  });
                }}
              >
                Reset
              </Button>

              {/* Apply Filters Button */}
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  // Trigger filtering logic
                  const filtered = filteredFlights();
                  console.log("Filtered Flights:", filtered);
                  // Optionally, update state or trigger UI actions
                }}
              >
                Apply Filters
              </Button>
            </Box>
          </CardContent>
        </Card>
        <Box width="70%">
          {/* <Typography variant="h5" sx={{ marginBottom: "1%" }}>
          Flight Results
        </Typography> */}
          {loading ? (
            <CircularProgress />
          ) : filteredFlights().length > 0 ? (
            filteredFlights().map((flight, index) => (
              <Card
                key={index}
                variant="elevation"
                elevation={3}
                //   sx={{ marginBottom: "16px", padding: "16px", display: "flex" }}
                sx={{ maxWidth: "100%", marginBottom: "2%" }}
              >
                <CardContent>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    //   alignItems="center"
                    sx={{ height: "100%", width: "100%" }}
                  >
                    <Box sx={{ flex: "20%" }}>
                      <Box display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                          <img
                            src={flightAirlineImg}
                            alt="airline"
                            style={{ width: 20, height: 20, marginRight: 8 }}
                          />
                          <Typography variant="subtitle1" fontWeight="bold">
                            {constructAirlinesForSegments(
                              flight.OriginDestinationOptions.flatMap(
                                (option) => option.FlightSegments
                              )
                            )}
                          </Typography>
                        </Box>
                        <Box>
                          <img
                            src={vectorImage}
                            alt="airline"
                            style={{ width: 20, height: 20, marginRight: 8 }}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      sx={{ flex: "60%" }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        sx={{ flex: "80%" }}
                      >
                        {flight.OriginDestinationOptions.flatMap(
                          (option) => option.FlightSegments
                        ).map((segment, index) => (
                          <Box
                            key={index}
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                              marginBottom: "2%",
                              backgroundColor: "#FEE69040",
                              borderRadius: "5px",
                            }}
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={{ flex: "33%", marginLeft: "1%" }}
                            >
                              <Typography variant="body2" color="textSecondary">
                                {new Date(
                                  segment.DepartureDateTime
                                ).toLocaleString("en-US", {
                                  weekday: "short",
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })}
                              </Typography>
                              <Typography variant="h6">
                                {new Date(
                                  segment.DepartureDateTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </Typography>
                              <Typography variant="body2">
                                {segment.DepartureAirportLocationCode}
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={{ flex: "33%", marginLeft: "3%" }}
                            >
                              <Typography variant="body4">{`${segment.OperatingAirline.Code}-${segment.OperatingAirline.FlightNumber}`}</Typography>
                              <Typography variant="body2" color="textSecondary">
                                {formatDuration(
                                  calculateTotalJourneyDuration(segment)
                                )}
                              </Typography>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Box
                                  sx={{
                                    height: "1px",
                                    backgroundColor: "#C8C8C8",
                                    flex: 1,
                                    marginRight: "8px",
                                  }}
                                />
                                <img
                                  src={planeImage}
                                  alt="plane"
                                  style={{ width: "24px", height: "24px" }}
                                />
                                <Box
                                  sx={{
                                    height: "1px",
                                    backgroundColor: "#C8C8C8",
                                    flex: 1,
                                    marginLeft: "8px",
                                  }}
                                />
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={{ flex: "33%", marginLeft: "3%" }}
                            >
                              <Typography variant="body2" color="textSecondary">
                                {new Date(
                                  segment.ArrivalDateTime
                                ).toLocaleString("en-US", {
                                  weekday: "short",
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })}
                              </Typography>
                              <Typography variant="h6">
                                {new Date(
                                  segment.ArrivalDateTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </Typography>
                              <Typography variant="body2">
                                {segment.ArrivalAirportLocationCode}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ flex: "20%", marginLeft: "2%" }}
                      >
                        <Typography variant="h6" color="textPrimary">
                          {
                            flight.AirItineraryPricingInfo.ItinTotalFare
                              .TotalFare.Amount
                          }{" "}
                          {
                            flight.AirItineraryPricingInfo.ItinTotalFare
                              .TotalFare.CurrencyCode
                          }
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ marginTop: "8px", width: "100%" }}
                          onClick={() => handleFlightBookClick(flight)}
                        >
                          Book Now
                        </Button>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      sx={{ flex: "20%", marginTop: "2%" }}
                    >
                      <Typography variant="body2" color="textSecondary">
                        100 seats remaining
                      </Typography>
                      <Typography variant="body2" color="warning.main">
                        Partially Refundable
                      </Typography>
                      <Typography variant="body2" color="warning.main">
                        View Flight Details
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography>No flights available</Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default FlightResults;
