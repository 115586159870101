// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/landingpageimage.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.landing-page {
    display: flex;
    height: 100vh;
  }
  
  .landing-left {
    width: 60%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
  }
  .landing-image-overlay {
    position: absolute;
    top: 10px; 
    left: 50%; 
    transform: translateX(-50%); 
    width: 424px; 
    height: 73px; 
    opacity: 0.9; 
  }
  
  .landing-text {
    font-size: 2.5rem;
    text-align: center;
  }
  
  .landing-right {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5%;
    background-color: white;
  }
  
  .landing-title {
    font-weight: 600;
    text-align: center;
    color: #003366;
    /* margin-bottom: 20px; */
  }
  
  .landing-signin {
    text-align: center;
    margin-top: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Authentication/SignUpStep1.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,aAAa;EACf;;EAEA;IACE,UAAU;IACV,yDAA0D;IAC1D,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;EACpB;EACA;IACE,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,YAAY;IACZ,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;EAClB","sourcesContent":["\n.landing-page {\n    display: flex;\n    height: 100vh;\n  }\n  \n  .landing-left {\n    width: 60%;\n    background-image: url('../../assets/landingpageimage.jpg');\n    background-size: cover;\n    background-position: center;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n    position: relative;\n  }\n  .landing-image-overlay {\n    position: absolute;\n    top: 10px; \n    left: 50%; \n    transform: translateX(-50%); \n    width: 424px; \n    height: 73px; \n    opacity: 0.9; \n  }\n  \n  .landing-text {\n    font-size: 2.5rem;\n    text-align: center;\n  }\n  \n  .landing-right {\n    width: 40%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    padding: 0 5%;\n    background-color: white;\n  }\n  \n  .landing-title {\n    font-weight: 600;\n    text-align: center;\n    color: #003366;\n    /* margin-bottom: 20px; */\n  }\n  \n  .landing-signin {\n    text-align: center;\n    margin-top: 20px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
