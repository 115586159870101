import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "bootstrap/dist/css/bootstrap.min.css";
import PaymentModal from "../PaymentModal";
import FlightDetailsCard from "./FlightDetailsCard";
import TopHeader from "../TopHeader";
import BookingDialog from "./BookingDialog";

const FlightDetails = ({ flight: initialFlight, flightSearchParams }) => {
  const [flight, setFlight] = useState(initialFlight);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [flightSegmentsString, setFlightSegmentsString] = useState(null);
  const { AirItineraryPricingInfo, OriginDestinationOptions } = flight;
  const flightSegments = OriginDestinationOptions.flatMap(
    (option) => option.FlightSegments
  );

  const underlineStyle = { textDecoration: "underline" };
  const [modalOpen, setModalOpen] = useState(false);
  const [bookingDialogOpen, setBookingDialogOpen] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const navigate = useNavigate();
  const detailsRef = useRef(null);
  const firstNameRef = useRef(null);
  const guestsDetailsRef = useRef(null);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [amount, setAmount] = useState(0);
  const [guestsData, setGuestsData] = useState([]);

  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    type: "AD", // Default to "Adult"
    nationality: "India",
    gender: "Male",
    dob: "",
    passportNumber: "",
    passportExpirationDate: "",
  });

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem("jwt");
      try {
        const response = await axios.post(
          "http://104.237.9.131:8080/api/flight/mystiFly/revalidate",
          {
            FareSourceCode:
              initialFlight.AirItineraryPricingInfo.FareSourceCode,
            Target: "Test",
            ConversationId: "anystring",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data?.Data?.PricedItineraries?.[0]) {
          setFlight(response.data.Data.PricedItineraries[0]);
          setAmount(
            flight.AirItineraryPricingInfo.ItinTotalFare.TotalFare.Amount
          );
        }
      } catch (err) {
        console.error("Error fetching flight revalidation data:", err);
        setError("Failed to fetch updated flight details. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    // Call fetchData only once when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures this is called only on mount

  useEffect(() => {
    // Scroll and focus only if guestsData.length > 0
    if (guestsData.length > 0) {
      guestsDetailsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      guestsDetailsRef.current.focus();
    }
    console.log("flightSegments", flightSegments);
    const flightSegmentsString1 = JSON.stringify(flightSegments);
    console.log("flightSegmentsString1=", flightSegmentsString1);
    setFlightSegmentsString(flightSegmentsString1);
  }, [guestsData]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <TopHeader />
        <Typography color="error">{error}</Typography>
        <Button variant="contained" onClick={() => window.location.reload()}>
          Retry
        </Button>
      </Box>
    );
  }

  const addGuest = () => {
    const childrenDataEntered = guestsData.filter(
      (guest) => guest.type === "CH"
    ).length;
    const adultDataEntered = guestsData.filter(
      (guest) => guest.type === "AD"
    ).length;
    const remainingChildrenTobeEntered =
      flightSearchParams.children - childrenDataEntered;
    const remainingAdultsTobeEntered =
      flightSearchParams.adults - adultDataEntered;
    if (remainingAdultsTobeEntered === 0 && formData.type === "AD") {
      alert(
        `You are not allowed to enter more adult guests as you have already entered all the adult guests data`
      );
      return;
    }
    if (remainingChildrenTobeEntered === 0 && formData.type === "CH") {
      alert(
        `You are not allowed to enter more children guests as you have already entered all the children guests data`
      );
      return;
    }
    const errors = {};
    Object.keys(formData).forEach((field) => {
      if (
        [
          "firstName",
          "lastName",
          "dob",
          "passportNumber",
          "passportExpirationDate",
        ].includes(field) &&
        formData[field].trim() === ""
      ) {
        errors[field] = true;
      }
    });

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      alert("Please fill in all required fields.");
      return;
    }
    setGuestsData([...guestsData, { ...formData }]);
    setFormData({
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      type: "AD",
      nationality: "India",
      gender: "Male",
      dob: "",
      passportNumber: "",
      passportExpirationDate: "",
    });
    setValidationErrors({});
    console.log("guestsData=", guestsData);
    if (guestsData.length > 0) {
      console.log("In here line 97");
      guestsDetailsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      guestsDetailsRef.current.focus();
    }
  };

  const removeGuest = (indexToRemove) => {
    setGuestsData(guestsData.filter((_, index) => index !== indexToRemove));
  };
  const validateGuestData = () => {
    const totalGuests = flightSearchParams.adults + flightSearchParams.children;
    if (guestsData.length < totalGuests) {
      const childrenDataEntered = guestsData.filter(
        (guest) => guest.type === "CH"
      ).length;
      const adultDataEntered = guestsData.filter(
        (guest) => guest.type === "AD"
      ).length;
      const remainingChildrenTobeEntered =
        flightSearchParams.children - childrenDataEntered;
      const remainingAdultsTobeEntered =
        flightSearchParams.adults - adultDataEntered;
      if (
        childrenDataEntered < flightSearchParams.children &&
        adultDataEntered < flightSearchParams.adults
      ) {
        alert(
          `Incomplete details: Please enter remaining ${remainingChildrenTobeEntered} number of Children data 
           and ${remainingAdultsTobeEntered} of adults out of total ${totalGuests} guests.`
        );
      } else if (childrenDataEntered < flightSearchParams.children) {
        alert(
          `Incomplete details: Please enter remaining ${remainingChildrenTobeEntered} number of Children data`
        );
      } else {
        alert(
          `Incomplete details: Please enter remaining ${remainingAdultsTobeEntered} number of adult guests`
        );
      }

      detailsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      firstNameRef.current.focus();
      return false;
    }
    return true;
  };

  const handleClick = (newRating) => {
    setRating(newRating);
  };

  const handleMouseEnter = (newHover) => {
    setHover(newHover);
  };

  const handleMouseLeave = () => {
    setHover(rating);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOpenModal = () => {
    if (!validateGuestData()) return;
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const injectCSS = () => {
    const link = document.createElement("link");
    link.href = "./razorpay-custom.css";
    link.type = "text/css";
    link.rel = "stylesheet";
    document.head.appendChild(link);
  };

  const createOrder = async () => {
    const response = await fetch(
      `http://104.237.9.131:8080/api/accommodation/api/payment/createOrder?amount=1&currency=INR`,
      // `http://104.237.9.131:8080/api/payment/createOrder?amount=${Math.round(
      //   amount
      // )}&currency=INR`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return await response.json();
  };

  const verifyPayment = async (orderId, paymentId, signature) => {
    console.log("orderId=", orderId);
    console.log("paymentId=", paymentId);
    console.log("signature=", signature);
    const response = await fetch(
      `http://104.237.9.131:8080/api/accommodation/api/payment/verifyPayment?orderId=${orderId}&paymentId=${paymentId}&signature=${signature}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return await response.text();
  };
  const handleDownload = () => {
    console.log("download pressed");
  };
  const handlePayment = async (paymentMethod) => {
    if (paymentMethod === "btc") {
      // Execute BTC-specific booking flow
      setLoading(true);
      console.log(
        "flight segment String before calling api=",
        flightSegmentsString
      );
      try {
        const bookingFlightRequest = {
          FlightSegments: flightSegmentsString,
          OriginLocationCode: flightSearchParams.fromAirportData.IATA,
          DestinationLocationCode: flightSearchParams.toAirportData.IATA,
          FareSourceCode: flight.AirItineraryPricingInfo.FareSourceCode,
          TravelerInfo: {
            AirTravelers: guestsData.map((guest) => ({
              PassengerType: guest.type === "AD" ? "ADT" : "CHD",
              Gender: guest.gender === "Male" ? "M" : "F",
              PassengerName: {
                PassengerTitle: "MR",
                PassengerFirstName: guest.firstName,
                PassengerLastName: guest.lastName,
              },
              DateOfBirth: guest.dob,
              Passport: {
                PassportNumber: guest.passportNumber,
                ExpiryDate: guest.passportExpirationDate,
                Country: "IN", // hardcoding for now
              },
              PassengerNationality: "IN",
              NationalID: "IN",
            })),
            CountryCode: "91",
            AreaCode: "080",
            PhoneNumber: "87657897",
            Email: "apisupport@mystifly.com",
            PostCode: "560028",
          },
          Target: "Test",
        };
        const userName = localStorage.getItem("username");
        const bookingRequest = {
          userName: userName,
          bookingFlightRequest: bookingFlightRequest,
        };
        console.log("bookingRequest=", bookingRequest);
        const bookingResponse = await fetch(
          "http://104.237.9.131:8080/api/flight/mystiFly/book",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            body: JSON.stringify(bookingRequest),
          }
        );

        if (bookingResponse.status === 200) {
          const responseData = await bookingResponse.json();
          console.log("responseData=", responseData);
          const bookingReference = responseData.Data.UniqueID;
          setBookingDetails({
            reference: bookingReference,
            guests: guestsData,
          });
          console.log("setting booking dialog open true");
          setBookingDialogOpen(true);
        } else {
          alert("Booking failed, please try again");
        }
      } catch (error) {
        console.error("Booking error:", error);
        alert("An error occurred during booking.");
      } finally {
        setLoading(false);
      }
      return;
    }

    // console.log("amount is =", amount);
    // const res = await loadScript(
    //   "https://checkout.razorpay.com/v1/checkout.js"
    // );
    // if (!res) {
    //   alert("Razorpay SDK failed to load. Are you online?");
    //   return;
    // }

    // const order = await createOrder();

    // const options = {
    //   key: "rzp_test_dRH92j43X0Pcxi",
    //   amount: Math.round(amount * 100), // Amount in paise
    //   currency: hotel.currency,
    //   name: "Bizflyte",
    //   description: "Test Hotel Checkout Transaction",
    //   order_id: order.id,
    //   handler: async function (response) {
    //     const result = await verifyPayment(
    //       response.razorpay_order_id,
    //       response.razorpay_payment_id,
    //       response.razorpay_signature
    //     );
    //     alert(result);
    //     if (result === "Payment is valid") {
    //       setLoading(true);
    //       // call the /accommodation/booking/book-hotel api after constructing the Proper BookingHotelRequestDTO
    //       // from existing data such as rate

    //       // Hardcoded booking request DTO with dynamic rateKey
    //       try {
    //         const bookingHotelRequest = {
    //           holder: {
    //             name: formData.firstName || "HolderFirstName",
    //             surname: formData.lastName || "HolderLastName",
    //           },
    //           rooms: [
    //             {
    //               rateKey: rate.rateKey, // Dynamic rateKey from the rate object
    //               paxes: guestsData.map((guest) => ({
    //                 roomId: 1,
    //                 type: guest.type,
    //                 name: guest.firstName,
    //                 surname: guest.lastName,
    //               })),
    //             },
    //           ],
    //           clientReference: "IntegrationAgency",
    //           remark: "Booking remarks are to be written here.",
    //           tolerance: 2,
    //         };
    //         const userName = localStorage.getItem("username");
    //         const bookingRequest = {
    //           userName: userName,
    //           bookingHotelRequest: bookingHotelRequest,
    //         };
    //         console.log("bookingRequest=", bookingRequest);
    //         const bookingResponse = await fetch(
    //           "http://104.237.9.131:8080/api/accommodation/booking/book-hotel",
    //           {
    //             method: "POST",
    //             headers: {
    //               "Content-Type": "application/json",
    //               Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    //             },
    //             body: JSON.stringify(bookingRequest),
    //           }
    //         );

    //         if (bookingResponse.status === 200) {
    //           const responseData = await bookingResponse.json();
    //           const bookingReference = responseData.booking.reference;
    //           // alert(
    //           //   "Payment is success, Booking is confirmed with booking id: " +
    //           //     bookingReference
    //           // );
    //           setBookingDetails({
    //             reference: bookingReference,
    //             guests: guestsData,
    //             hotelName: hotel.name,
    //             checkIn: searchParams.checkIn,
    //             checkOut: searchParams.checkOut,
    //             rooms: searchParams.rooms,
    //           });
    //           setBookingDialogOpen(true);
    //           // navigate("/HotelDashBoard");
    //         } else {
    //           alert("Booking failed, please try again");
    //         }
    //       } catch (error) {
    //         console.error("Booking error:", error);
    //         alert("An error occurred during booking.");
    //       } finally {
    //         setLoading(false);
    //       }
    //     }
    //   },
    //   prefill: {
    //     name: "Aditya Ducima",
    //     email: "your.email@example.com",
    //     contact: "9999999999",
    //   },
    //   theme: {
    //     color: "#0054a1",
    //   },
    //   modal: {
    //     ondismiss: function () {
    //       console.log("Checkout form closed");
    //     },
    //   },
    // };

    // const paymentObject = new window.Razorpay(options);
    // paymentObject.open();
    // // Resizing the modal after it has opened
    // setTimeout(() => {
    //   const modal = document.querySelector(".razorpay-checkout-frame");
    //   if (modal) {
    //     if (modal) {
    //       modal.style.height = "90%";
    //       modal.style.width = "350px";
    //       modal.style.top = "50%";
    //       modal.style.left = "50%";
    //       modal.style.transform = "translate(-50%, -50%)";
    //     }
    //   }
    // }, 1000); // Adjust the timeout if necessary
  };

  // const symbol = getCurrencySymbol({ hotel });
  const handleCloseBookingDialog = () => {
    setBookingDialogOpen(false);
    navigate("/HotelDashBoard");
  };

  // end

  return (
    <Box sx={{ padding: "1%" }}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <FlightDetailsCard flightSegments={flightSegments} />

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">Important Guidelines</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                When booking a flight, it is important for passengers to follow
                certain guidelines to ensure a smooth and stress-free
                experience:
              </Typography>
              <Typography variant="body2" component="ul">
                <li>
                  <strong>Double-Check Travel Details:</strong> Before
                  confirming your booking, carefully review all travel details,
                  including dates, destinations, and passenger information.
                  Ensure that the information entered is accurate to avoid
                  complications later.
                </li>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Card sx={{ marginTop: "2%", marginBottom: "2%" }}>
            <CardContent>
              <Typography variant="h6">
                Contact Details (Contact Details will be sent)
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Country Code"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Mobile Number"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Email" variant="outlined" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card ref={detailsRef} sx={{ marginBottom: "1%" }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{ marginTop: "2%", marginBottom: "2%" }}
              >
                Passenger Details
              </Typography>

              <form>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      select
                      label="Type"
                      name="type"
                      value={formData.type}
                      onChange={handleChange}
                      fullWidth
                    >
                      <MenuItem value="AD">Adult</MenuItem>
                      <MenuItem value="CH">Child</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="First and middle Name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      fullWidth
                      required
                      inputRef={firstNameRef}
                      error={validationErrors.firstName}
                      helperText={
                        validationErrors.firstName
                          ? "First name is required"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      fullWidth
                      required
                      error={validationErrors.lastName}
                      helperText={
                        validationErrors.lastName ? "Last name is required" : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Nationality"
                      name="nationality"
                      value={formData.nationality}
                      onChange={handleChange}
                      fullWidth
                    >
                      <MenuItem value="India">Indian</MenuItem>
                      <MenuItem value="America">American</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Gender"
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      fullWidth
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Date of Birth"
                      name="dob"
                      type="date"
                      value={formData.dob}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      error={validationErrors.dob}
                      helperText={
                        validationErrors.dob ? "Date of Birth is required" : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Passport Number"
                      variant="outlined"
                      name="passportNumber"
                      value={formData.passportNumber}
                      onChange={handleChange}
                      error={validationErrors.passportNumber}
                      helperText={
                        validationErrors.passportNumber
                          ? "Passport number is required"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Passport Expiration Date"
                      type="date"
                      name="passportExpirationDate"
                      InputLabelProps={{ shrink: true }}
                      value={formData.passportExpirationDate}
                      onChange={handleChange}
                      error={validationErrors.passportExpirationDate}
                      helperText={
                        validationErrors.passportExpirationDate
                          ? "Passport expiry date is required"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {/* <Button onClick={addGuest} variant="outlined">
                      + Add Guest
                    </Button> */}
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={addGuest}
                    >
                      Add Passenger
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">Cancellation/Refund Policy</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                At GlobGoer, we understand that plans can change unexpectedly.
                We strive to provide a flexible and customer-friendly
                cancellation and refund policy for flight bookings. Please
                review the following policy details:
              </Typography>
              <Typography>
                <strong>Cancellation requests:</strong>
              </Typography>
              <Typography variant="body2" component="ul">
                <li>
                  All cancellation requests must be submitted through our
                  website or by contacting customer support team
                </li>
                <li>
                  Cancellation requests made through our website should be done
                  by accessing your booking and following the cancellation
                  process
                </li>
              </Typography>
            </AccordionDetails>
          </Accordion>
          {guestsData.length > 0 && (
            <Card elevation="5" sx={{ marginTop: "2%", marginBottom: "2%" }}>
              <CardContent>
                <Typography variant="h6" tabIndex="-1" ref={guestsDetailsRef}>
                  You have entered following Guests Details
                </Typography>
                {guestsData.map((guest, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <Typography variant="body2" style={{ marginRight: "16px" }}>
                      Name: {guest.firstName} {guest.lastName} <br />
                      Type: {guest.type === "AD" ? "Adult" : "Child"} <br />
                      Date of Birth: {guest.dob} <br />
                      Passport Number: {guest.passportNumber} <br />
                      Passport Expiration Date: {guest.passportExpirationDate}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => removeGuest(index)}
                      style={{ marginLeft: "auto" }}
                    >
                      Remove
                    </Button>
                  </div>
                ))}
              </CardContent>
            </Card>
          )}
        </Grid>

        {/* Right Section - Fare Summary */}
        <Grid item xs={3}>
          <Card elevation="5" sx={{ marginBottom: "3%" }}>
            <CardContent>
              <Typography variant="h6">Fare Summary</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2">Base Price:</Typography>
                  <Typography variant="body2">
                    {flightSearchParams.adults + flightSearchParams.children}{" "}
                    Passengers
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" align="right">
                    {AirItineraryPricingInfo.ItinTotalFare.EquivFare.Amount}{" "}
                    {
                      AirItineraryPricingInfo.ItinTotalFare.EquivFare
                        .CurrencyCode
                    }
                  </Typography>
                </Grid>
                <Divider sx={{ margin: "2%", width: "100%", opacity: 2.0 }} />
                <Grid item xs={6}>
                  <Typography variant="body2">Taxes & Fees:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" align="right">
                    {AirItineraryPricingInfo.ItinTotalFare.TotalTax.Amount}{" "}
                    {
                      AirItineraryPricingInfo.ItinTotalFare.TotalTax
                        .CurrencyCode
                    }
                  </Typography>
                </Grid>
                <Divider sx={{ margin: "2%", width: "100%", opacity: 2.0 }} />
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      backgroundColor: "#F5F5F5",
                      borderRadius: "4px",
                    }}
                  >
                    <Grid item xs={6}>
                      <Typography variant="body4">
                        Total amount to be paid:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" align="right">
                        {AirItineraryPricingInfo.ItinTotalFare.TotalFare.Amount}{" "}
                        {
                          AirItineraryPricingInfo.ItinTotalFare.TotalFare
                            .CurrencyCode
                        }
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card elevation="5" sx={{ marginBottom: "3%" }}>
            <CardContent>
              <div class="coupon-codes mb-3 cashback">
                <div className="price-breakup">Coupon Codes</div>
                <div className="form-check mt-2">
                  <input type="radio" className="form-check-input" />
                  <label className="form-check-label color-cashback">
                    CASHBACK
                  </label>
                </div>
                <div className="price-cashback">
                  Congratulations!! Cashback of $50 will be credited to your
                  card within 3 days of booking
                </div>
              </div>
            </CardContent>
          </Card>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              fullWidth
            >
              Continue
            </Button>
          )}
        </Grid>
      </Grid>
      <PaymentModal
        open={modalOpen}
        onClose={handleCloseModal}
        amount={amount}
        handlePayment={handlePayment}
        checkrateKey={"NA"}
        perDayRent={AirItineraryPricingInfo.ItinTotalFare.TotalFare.Amount}
      />
      <BookingDialog
        bookingDialogOpen={bookingDialogOpen}
        handleCloseBookingDialog={handleCloseBookingDialog}
        bookingReference={bookingDetails?.reference}
        flightSegments={flightSegments}
      />
    </Box>
  );
};

export default FlightDetails;
