import React from "react";
import { Box, Typography } from "@mui/material";

const FlightContent = () => (
  <Box>
    <Typography>Flight content</Typography>
  </Box>
);

export default FlightContent;
