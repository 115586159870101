import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Container, Link, Button } from "@mui/material";
import RoomDetails from "./RoomDetails";
import "./CheckOut.css";
import { useLocation } from "react-router-dom";
import logo from "../../assets/logo/logo512.png";
import AuthContext from "../../contexts/AuthProvider";

const CheckOut = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, setIsLoggedIn, handleLogoutClick, setRole } =
    useContext(AuthContext);
  const { hotel, room, rate, searchParams } = location.state || {};
  const handleLogOutOnCheckoutPage = () => {
    handleLogoutClick();
    navigate("/");
  };
  useEffect(() => {
    const token = localStorage.getItem("jwt");
    if (token) {
      setIsLoggedIn(true);
      // Assume role retrieval logic if stored somewhere
    } else {
      setIsLoggedIn(false);
    }
  }, []);
  const handleCheckStatusClick = () => {
    // Navigate to BookingHistory.js
    navigate("/booking-history");
  };
  return (
    <Box
      sx={{
        backgroundColor: "#0054A1",
        height: "100px",
        width: "100%",
        margin: "0 auto",
        position: "relative",
      }}
    >
      <Container
        sx={{
          position: "relative",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "30px 0",
          }}
        >
          <Typography variant="h6" color="inherit">
            <img src={logo} alt="Logo" width="100px" />
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              fontSize: "14px",
              fontFamily: "Noto Sans, sans-serif",
              fontWeight: "300",
            }}
          >
            <Link href="/offers" underline="none" sx={{ color: "#FFFFFF" }}>
              Offers
            </Link>
            <Link
              onClick={handleCheckStatusClick} // Use the navigate function
              underline="none"
              sx={{ color: "#FFFFFF", cursor: "pointer" }} // Set cursor to pointer for better UX
            >
              Check Status
            </Link>
            <Link
              href="/add-members"
              underline="none"
              sx={{ color: "#FFFFFF" }}
            >
              Add Members
            </Link>
            {isLoggedIn ? (
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  padding: "4px 20px !important",
                  fontSize: "14px",
                  fontFamily: "Noto Sans, sans-serif",
                  fontWeight: "300",
                  textTransform: "capitalize",
                  width: "100px",
                }}
                onClick={handleLogOutOnCheckoutPage}
              >
                Log Out
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  padding: "4px 20px !important",
                  fontSize: "14px",
                  fontFamily: "Noto Sans, sans-serif",
                  fontWeight: "300",
                  textTransform: "capitalize",
                  width: "100px",
                }}
                // onClick={handleLoginClick}
              >
                Login
              </Button>
            )}
          </Box>
        </Box>
      </Container>
      <div className="checkout-page">
        <RoomDetails
          hotel={hotel}
          room={room}
          rate={rate}
          searchParams={searchParams}
        />
      </div>
    </Box>
  );
};

export default CheckOut;
