import React, { useState, useRef } from "react";
import {
  TextField,
  IconButton,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown, Edit } from "@mui/icons-material";
import { Autocomplete } from "@react-google-maps/api";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "./SearchSection.css";

const SearchSection = ({ onSearch }) => {
  const [location, setLocation] = useState("Washington");
  const [checkIn, setCheckIn] = useState(
    dayjs(new Date(new Date().setDate(new Date().getDate() + 2)))
  );
  const [checkOut, setCheckOut] = useState(
    dayjs(new Date(new Date().setDate(new Date().getDate() + 3)))
  );
  const [guests, setGuests] = useState(1);
  const [rooms, setRooms] = useState(1);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [paxes, setPaxes] = useState([]);
  const [isGuestsDialogOpen, setIsGuestsDialogOpen] = useState(false);
  const [isChildAgeDialogOpen, setIsChildAgeDialogOpen] = useState(false);
  const [childAge, setChildAge] = useState("");
  const [coordinates, setCoordinates] = useState(null);
  const [isCheckInInputVisible, setIsCheckInInputVisible] = useState(false);
  const [isCheckOutInputVisible, setIsCheckOutInputVisible] = useState(false);
  const [isLocationInputVisible, setIsLocationInputVisible] = useState(false);
  const [isGuestsInputVisible, setIsGuestsInputVisible] = useState(false);
  const [editChildIndex, setEditChildIndex] = useState(null);

  const autocompleteRef = useRef(null);
  const handleAddChildAge = () => {
    setChildAge("");
    setEditChildIndex(null);
    setIsChildAgeDialogOpen(true);
  };
  const handleChildAgeSubmit = () => {
    if (editChildIndex !== null) {
      // Editing an existing child
      const updatedPaxes = [...paxes];
      updatedPaxes[editChildIndex].age = parseInt(childAge);
      setPaxes(updatedPaxes);
    } else {
      // Adding a new child
      setPaxes([...paxes, { type: "CH", age: parseInt(childAge) }]);
      setChildren(children + 1);
    }
    setChildAge("");
    setIsChildAgeDialogOpen(false);
  };
  const handleGuestsDialogOpen = () => setIsGuestsDialogOpen(true);
  const handleGuestsDialogClose = () => setIsGuestsDialogOpen(false);
  const updateGuestsCount = () => {
    setGuests(adults + children);
    handleGuestsDialogClose();
  };
  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    setLocation(place.formatted_address);
    setCoordinates({
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    });
    setIsLocationInputVisible(false);
  };

  const handleSearch = () => {
    onSearch({
      location,
      checkIn: checkIn.format("YYYY-MM-DD"),
      checkOut: checkOut.format("YYYY-MM-DD"),
      guests,
      adults,
      children,
      paxes,
      rooms,
      coordinates,
    });
  };

  const toggleCheckInInput = () => {
    setIsCheckInInputVisible(!isCheckInInputVisible);
  };

  const toggleCheckOutInput = () => {
    setIsCheckOutInputVisible(!isCheckOutInputVisible);
  };

  const formatDate = (date) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const getWeekDay = (date) => {
    const options = { weekday: "long" };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  const handleEditChildAge = (index) => {
    setChildAge(paxes[index].age.toString());
    setEditChildIndex(index);
    setIsChildAgeDialogOpen(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{ width: "100%", position: "relative", textAlign: "center" }}
        className="search-section-container"
      >
        <div className="search-fields filter-container">
          <div
            className="search-field"
            onClick={() => setIsLocationInputVisible(!isLocationInputVisible)}
          >
            {isLocationInputVisible ? (
              <Autocomplete
                onLoad={(autocomplete) =>
                  (autocompleteRef.current = autocomplete)
                }
                onPlaceChanged={handlePlaceChanged}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  value={location}
                  onChange={(e) => {
                    setLocation(e.target.value);
                    setCoordinates(null); // Reset coordinates if the user types manually
                  }}
                  autoFocus
                  placeholder="Enter a location"
                />
              </Autocomplete>
            ) : (
              <>
                <div className="search-field-label">
                  City, Property, or Location
                </div>
                <div className="search-field-value">{location}</div>
              </>
            )}
          </div>

          <div className="search-field">
            {isCheckInInputVisible ? (
              <DatePicker
                label="Check-In"
                fullWidth
                value={checkIn}
                onChange={(newDate) => setCheckIn(newDate)}
                onAccept={toggleCheckInInput}
                onClose={toggleCheckInInput}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            ) : (
              <>
                <div
                  className="search-field-label"
                  onClick={toggleCheckInInput}
                >
                  Check-In
                </div>
                <div
                  className="search-field-value"
                  onClick={toggleCheckInInput}
                >
                  {formatDate(checkIn)}
                </div>
                <div
                  className="search-field-label"
                  onClick={toggleCheckInInput}
                >
                  {getWeekDay(checkIn)}
                </div>
              </>
            )}
          </div>

          <div className="search-field">
            {isCheckOutInputVisible ? (
              <DatePicker
                fullWidth
                label="Check-Out"
                value={checkOut}
                onChange={(newDate) => setCheckOut(newDate)}
                onAccept={toggleCheckOutInput}
                onClose={toggleCheckOutInput}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            ) : (
              <>
                <div
                  className="search-field-label"
                  onClick={toggleCheckOutInput}
                >
                  Check-Out
                </div>
                <div
                  className="search-field-value"
                  onClick={toggleCheckOutInput}
                >
                  {formatDate(checkOut)}
                </div>
                <div
                  className="search-field-label"
                  onClick={toggleCheckOutInput}
                >
                  {getWeekDay(checkOut)}
                </div>
              </>
            )}
          </div>

          <div className="search-field" onClick={handleGuestsDialogOpen}>
            <div className="search-field-label">Rooms & Guests</div>
            <div className="search-field-value">
              {rooms} Room, {adults + children} Guests
            </div>
          </div>
        </div>
        <Dialog open={isGuestsDialogOpen} onClose={handleGuestsDialogClose}>
          <DialogTitle>Rooms and Guests</DialogTitle>
          <DialogContent>
            <Box display="flex" alignItems="center" marginBottom={2}>
              <Typography>Rooms</Typography>
              <IconButton onClick={() => setRooms(rooms + 1)}>
                <KeyboardArrowUp />
              </IconButton>
              <Typography>{rooms}</Typography>
              <IconButton onClick={() => setRooms(Math.max(1, rooms - 1))}>
                <KeyboardArrowDown />
              </IconButton>
            </Box>
            <Box display="flex" alignItems="center" marginBottom={2}>
              <Typography>Adults</Typography>
              <IconButton onClick={() => setAdults(adults + 1)}>
                <KeyboardArrowUp />
              </IconButton>
              <Typography>{adults}</Typography>
              <IconButton onClick={() => setAdults(Math.max(1, adults - 1))}>
                <KeyboardArrowDown />
              </IconButton>
            </Box>
            <Box display="flex" alignItems="center" marginBottom={2}>
              <Typography>Children</Typography>
              <IconButton onClick={handleAddChildAge}>
                <KeyboardArrowUp />
              </IconButton>
              <Typography>{children}</Typography>
              <IconButton
                onClick={() => setChildren(Math.max(0, children - 1))}
              >
                <KeyboardArrowDown />
              </IconButton>
            </Box>
            {paxes.map((child, index) => (
              <Box
                display="flex"
                alignItems="center"
                key={index}
                marginBottom={1}
              >
                <Typography>
                  Child {index + 1}: Age {child.age}
                </Typography>
                <IconButton onClick={() => handleEditChildAge(index)}>
                  <Edit />
                </IconButton>
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={updateGuestsCount}>OK</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isChildAgeDialogOpen}
          onClose={() => setIsChildAgeDialogOpen(false)}
        >
          <DialogTitle>Enter Age of the Child</DialogTitle>
          <DialogContent>
            <TextField
              label="Age"
              value={childAge}
              onChange={(e) => setChildAge(e.target.value)}
              type="number"
              fullWidth
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleChildAgeSubmit}>OK</Button>
          </DialogActions>
        </Dialog>

        <div className="search-button-container">
          <Button
            className="search-button"
            variant="contained"
            color="primary"
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </Box>
    </LocalizationProvider>
  );
};

export default SearchSection;
