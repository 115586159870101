import React, { useState, useContext } from "react";
import AuthContext from "../contexts/AuthProvider";
import {
  Box,
  Typography,
  Grid,
  Paper,
  IconButton,
  TextField,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PeopleIcon from "@mui/icons-material/People";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AnalyticsIcon from "@mui/icons-material/BarChart"; // Example icon for the "Control Panel" text
import NotificationsIcon from "@mui/icons-material/Notifications";
import profileImage from "../assets/profileImage.png"; // Ensure this path is correct

const Sidebar = () => {
  const navigate = useNavigate();

  const menuItems = [
    { text: "Dashboard", icon: <DashboardIcon />, path: "/AdminDashBoard" },
    {
      text: "Activate Sign up",
      icon: <GroupAddIcon />,
      path: "/activate-signup",
    },
    { text: "Active Clients", icon: <PeopleIcon />, path: "/active-clients" },
    {
      text: "Discount Coupons",
      icon: <LocalOfferIcon />,
      path: "/discount-coupons",
    },
  ];

  return (
    <Box
      sx={{
        width: 240,
        height: "100vh",
        backgroundColor: "#FFFFFF",
        color: "#000",
        display: "flex",
        flexDirection: "column",
        paddingTop: 2,
      }}
    >
      <Box
        sx={{
          paddingLeft: 2,
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <AnalyticsIcon sx={{ color: "#000", marginRight: 1 }} />
        {/* Icon next to Control Panel */}
        <Box>
          <Typography
            variant="h6"
            noWrap
            sx={{
              fontFamily: "Microsoft JhengHei UI",
              fontWeight: 700,
              color: "#000000",
            }}
          >
            Control Panel
          </Typography>
          <Typography
            variant="subtitle1"
            noWrap
            sx={{
              fontFamily: "Microsoft JhengHei UI",
              fontWeight: 400,
              color: "#555555",
            }}
          >
            Analytics
          </Typography>
        </Box>
      </Box>
      <Box sx={{ paddingLeft: 2, paddingTop: 2 }}>
        {menuItems.map((item) => (
          <Box
            key={item.text}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px 15px",
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor:
                item.path === window.location.pathname
                  ? "#0054A1"
                  : "transparent",
              "&:hover": {
                backgroundColor: "#0054A1",
                "& .MuiTypography-root": {
                  color: "#FFFFFF", // Change font color to white on hover
                },
                "& .MuiSvgIcon-root": {
                  color: "#FFFFFF", // Change icon color to white on hover
                },
              },
            }}
            onClick={() => navigate(item.path)}
          >
            <Box
              sx={{
                color:
                  item.path === window.location.pathname ? "#FFFFFF" : "#000",
              }}
            >
              {item.icon}
            </Box>
            <Typography
              variant="body1"
              sx={{
                marginLeft: "10px",
                color:
                  item.path === window.location.pathname ? "#FFFFFF" : "#000",
                fontFamily: "Microsoft JhengHei UI",
                fontWeight: 500, // Adjust the weight as per your Figma design
              }}
            >
              {item.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const DashboardCard = ({ title, value, subtitle }) => {
  const subtitleParts = subtitle.split("since");
  return (
    <Paper sx={{ padding: 2, textAlign: "center", color: "text.secondary" }}>
      <Typography
        variant="h4"
        sx={{
          fontFamily: "Microsoft JhengHei UI",
          fontWeight: 700,
          color: "#000000",
        }}
      >
        {value}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          fontFamily: "Microsoft JhengHei UI",
          fontWeight: 500,
          color: "#555555",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Microsoft JhengHei UI",
          fontWeight: 400,
        }}
      >
        <span
          style={{
            transform: "rotate(45deg)",
            display: "inline-block",
            marginRight: "4px",
            color: "#4CAF50",
          }}
        >
          ↑
        </span>
        <Typography
          variant="body2"
          sx={{ color: "#4CAF50", marginRight: "4px" }}
        >
          1.20%
        </Typography>
        <Typography variant="body2" sx={{ color: "#555555" }}>
          {`since ${subtitleParts[1]}`}{" "}
          {/* Non-green text for the rest of the subtitle */}
        </Typography>
      </Typography>
    </Paper>
  );
};

const AdminDashBoard = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { handleLogoutClick } = useContext(AuthContext);
  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    handleLogoutClick();
    navigate("/");
  };

  return (
    <Box display="flex">
      <Sidebar />
      <Box flexGrow={1} p={3}>
        {/* Header with Search, Notification, and Profile */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" gutterBottom>
            Dashboard
          </Typography>
          <Box display="flex" alignItems="center">
            <TextField variant="outlined" placeholder="Search" size="small" />
            <IconButton>
              <NotificationsIcon />
            </IconButton>
            <IconButton onClick={handleProfileClick}>
              <Avatar src={profileImage} alt="Profile" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Box>
        {/* Dashboard Content */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard
              title="Total Sign Up"
              value="2400"
              subtitle="1.20% since last month"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard
              title="Active Customers"
              value="200"
              subtitle="1.20% since last month"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard
              title="Rejected Customer"
              value="10"
              subtitle="1.20% since last month"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard
              title="BTC Customers"
              value="49"
              subtitle="1.20% since last month"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdminDashBoard;
