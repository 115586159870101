// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whiteText {
    color: white;
  }

.container{
    background-color: #24A0ED;
}

.card {
    width: 300px;
    height: 200px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
  }

  .checkout-page{
    background: #f8f8f8;
    /* box-shadow: none !important; */
  }`, "",{"version":3,"sources":["webpack://./src/components/RoomCheckOut/CheckOut.css"],"names":[],"mappings":"AAAA;IACI,YAAY;EACd;;AAEF;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;IACnB,iCAAiC;EACnC","sourcesContent":[".whiteText {\r\n    color: white;\r\n  }\r\n\r\n.container{\r\n    background-color: #24A0ED;\r\n}\r\n\r\n.card {\r\n    width: 300px;\r\n    height: 200px;\r\n    padding: 20px;\r\n    background-color: #ffffff;\r\n    border: 1px solid #e0e0e0;\r\n    border-radius: 8px;\r\n  }\r\n\r\n  .checkout-page{\r\n    background: #f8f8f8;\r\n    /* box-shadow: none !important; */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
