import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import btcImage from "../assets/btc-image.png";
import creditCardImage from "../assets/credit-card.png";
import payNowImage from "../assets/pay-now-image.png";

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontFamily: "Microsoft JhengHei UI",
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "30.48px",
}));

const StyledPrice = styled(Typography)(({ theme }) => ({
  fontFamily: "Microsoft JhengHei UI",
  fontWeight: 400,
  fontSize: "36px",
  lineHeight: "48px",
}));

const ProceedButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#007bff",
  color: "white",
  "&:hover": {
    backgroundColor: "#0056b3",
  },
}));

const PaymentModal = ({
  open,
  onClose,
  amount,
  handlePayment,
  checkrateKey,
  perDayRent,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("payNow");

  const handleProceedClick = async () => {
    if (checkrateKey === "NA") {
      handlePayment(selectedPaymentMethod);
      return;
    }
    console.log("selectedPaymentMethod=", selectedPaymentMethod);
    setLoading(true);

    try {
      const response = await fetch(
        "http://104.237.9.131:8080/api/accommodation/booking/checkRates",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          body: JSON.stringify({
            rooms: [
              {
                rateKey: checkrateKey,
              },
            ],
          }),
        }
      );

      const data = await response.json();
      console.log("data=", data);
      if (data.hotel) {
        const fetchedAmount = data.hotel.totalNet;
        console.log(
          "data.hotel.rooms[0].rates[0].rateType=",
          data.hotel.rooms[0].rates[0].rateType
        );
        if (
          fetchedAmount != perDayRent ||
          data.hotel.rooms[0].rates[0].rateType != "BOOKABLE"
        ) {
          alert(
            `Per day Booking amount has changed to ${fetchedAmount}. Please review before proceeding.`
          );
        } else {
          alert(
            "Checkrate is success, Quoted rate is Bookable. Please Press Ok to proceed with payment"
          );
          handlePayment(selectedPaymentMethod);
        }
      }
    } catch (error) {
      console.error("Error fetching rates:", error);
    } finally {
      setLoading(false);
    }

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: 2,
              }}
            >
              <StyledDialogTitle>Proceed Payment with</StyledDialogTitle>
              <StyledPrice variant="h4">US ${amount}</StyledPrice>
              <RadioGroup
                value={selectedPaymentMethod}
                onChange={(e) => setSelectedPaymentMethod(e.target.value)}
              >
                <FormControlLabel
                  value="btc"
                  control={<Radio />}
                  label="BTC (Bill to Company)"
                />
                <FormControlLabel
                  value="payNow"
                  control={<Radio />}
                  label="Pay Now"
                />
              </RadioGroup>
              <Typography variant="body2" color="textSecondary">
                By proceeding, I agree to Bizflyte User Agreement, Terms of
                Service and Cancellation & Property Booking Policies.
              </Typography>
              <Box mt={2} mb={2}>
                {loading ? (
                  <Box textAlign="center">
                    <CircularProgress />
                    <Typography variant="body2" mt={2}>
                      Fetching the recent rate changes if any for the booking...
                    </Typography>
                  </Box>
                ) : (
                  <ProceedButton
                    fullWidth
                    variant="contained"
                    onClick={handleProceedClick}
                  >
                    Proceed
                  </ProceedButton>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: 2,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={creditCardImage}
                  alt="Credit Card"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Box
                sx={{
                  flexGrow: 0,
                  flexBasis: "20%",
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  <img
                    src={btcImage}
                    alt="BTC"
                    style={{
                      width: "24px",
                      height: "24px",
                      verticalAlign: "middle",
                      marginRight: "8px",
                    }}
                  />
                  BTC - Bill to Company <br />
                  Your company will pay for this trip
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 0,
                  flexBasis: "20%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ marginTop: "16px" }}
                >
                  <img
                    src={payNowImage}
                    alt="Pay Now"
                    style={{
                      width: "24px",
                      height: "24px",
                      verticalAlign: "middle",
                      marginRight: "8px",
                    }}
                  />
                  Pay Now - Available Options <br />
                  Use Credit Cards, Debit Cards, Netbanking and Wallets to pay
                  for this trip
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentModal;
