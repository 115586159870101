// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #f8f8f8;
  box-shadow: none !important;
}

.hotel-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
}

.hotel-card-container {
  width: 235px;
  height: auto;
  padding: 8px 8px;
  background: #fff;
  border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/MainContent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;EACT,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".main-content-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  padding: 20px;\r\n  background: #f8f8f8;\r\n  box-shadow: none !important;\r\n}\r\n\r\n.hotel-list-container {\r\n  display: grid;\r\n  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));\r\n  gap: 20px;\r\n  width: 100%;\r\n  max-width: 1200px;\r\n}\r\n\r\n.hotel-card-container {\r\n  width: 235px;\r\n  height: auto;\r\n  padding: 8px 8px;\r\n  background: #fff;\r\n  border-radius: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
