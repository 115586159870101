import GoogleIcon from "@mui/icons-material/Google";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FlightImage from "../../assets/flight.png";
import logo from "../../assets/logo/logoSignUp.png";
import AuthContext from "../../contexts/AuthProvider";
import LoginDialog from "../LoginDialog";
import CustomStepIcon from "./CustomStepIcon";
import "./SignUpStep1.css";

const steps = ["Step 1", "Step 2", "Step 3"];

const SignUpStep1 = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const { isLoggedIn, setIsLoggedIn, setRole, role } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    workEmail: "",
    firstName: "",
    lastName: "",
    termsAccepted: false,
    jobTitle: "",
    phoneNumber: "",
    companyName: "",
    peopleCount: "",
    currency: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (activeStep === 0) {
      if (!formData.workEmail) newErrors.workEmail = "Work Email is required";
      if (!formData.firstName) newErrors.firstName = "First Name is required";
      if (!formData.lastName) newErrors.lastName = "Last Name is required";
      if (!formData.termsAccepted)
        newErrors.termsAccepted = "You must accept the Terms of Use";
    } else if (activeStep === 1) {
      if (!formData.jobTitle) newErrors.jobTitle = "Job title is required";
      if (!formData.phoneNumber)
        newErrors.phoneNumber = "Phone number is required";
      if (!formData.companyName)
        newErrors.companyName = "Company name is required";
      if (!formData.peopleCount)
        newErrors.peopleCount = "Please select an option";
      if (!formData.currency) newErrors.currency = "Please select a currency";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = async () => {
    if (!validateForm()) return;

    if (activeStep === 1) {
      setLoading(true);
      try {
        await axios.post(
          "http://104.237.9.131:8080/api/userauth/register2",
          formData
        );
        setActiveStep(2);
      } catch (error) {
        console.error("Error registering user:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleStepClick = (step) => {
    setActiveStep(step);
  };

  const handleCloseLoginDialog = () => {
    setLoginDialogOpen(false);
  };

  const handleLoginClick = () => {
    setLoginDialogOpen(true);
  };

  useEffect(() => {
    if (isLoggedIn) {
      console.log("I am here in SignupStep1, role=", role);
      if (role.includes("ROLE_ADMIN")) {
        navigate("/AdminDashBoard");
      } else {
        navigate("/");
      }
    }
  }, []);

  return (
    <div className="landing-page">
      <Box className="landing-left">
        <img src={FlightImage} alt="Flight" className="landing-image-overlay" />
        <Typography variant="h3" className="landing-text">
          One stop for your <br />
          <strong>travel & stay</strong>
        </Typography>
      </Box>
      <Box className="landing-right">
        <Typography
          sx={{ margin: "5%" }}
          variant="h6"
          className="landing-title"
        >
          <img src={logo} alt="Logo" width="55%" />
        </Typography>

        <Stepper sx={{ margin: "5%" }} activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel
                onClick={() => handleStepClick(index)}
                StepIconComponent={CustomStepIcon}
                style={{ cursor: "pointer" }}
              />
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <>
            <TextField
              label="Work Email"
              variant="outlined"
              fullWidth
              margin="normal"
              name="workEmail"
              value={formData.workEmail}
              onChange={handleChange}
              error={!!errors.workEmail}
              helperText={errors.workEmail}
            />
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              error={!!errors.firstName}
              helperText={errors.firstName}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              error={!!errors.lastName}
              helperText={errors.lastName}
            />

            <Box display="flex" alignItems="center" marginBottom={2}>
              <Checkbox
                name="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleChange}
              />
              <Typography
                variant="body2"
                color={errors.termsAccepted ? "error" : "inherit"}
              >
                I've read and accept the <Link href="#">Terms of Use</Link>
              </Typography>
            </Box>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleNext}
            >
              Get Started
            </Button>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<GoogleIcon />}
              fullWidth
              style={{ marginTop: "10px" }}
            >
              Sign in with Google
            </Button>

            <Typography variant="body2" className="landing-signin">
              Already have an account?{" "}
              <Link href="#" onClick={() => handleLoginClick()}>
                Sign in
              </Link>
            </Typography>
          </>
        )}

        {activeStep === 1 && (
          <>
            <FormControl fullWidth margin="normal" error={!!errors.jobTitle}>
              <InputLabel>What’s your job title</InputLabel>
              <Select
                label="What’s your job title"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
              >
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="Manager">Manager</MenuItem>
                <MenuItem value="Developer">Developer</MenuItem>
                <MenuItem value="Designer">Designer</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
              {errors.jobTitle && (
                <Typography variant="caption" color="error">
                  {errors.jobTitle}
                </Typography>
              )}
            </FormControl>
            <TextField
              label="What’s your phone number"
              variant="outlined"
              fullWidth
              margin="normal"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
            />
            <TextField
              label="What’s your company’s name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              error={!!errors.companyName}
              helperText={errors.companyName}
            />
            <FormControl fullWidth margin="normal" error={!!errors.peopleCount}>
              <InputLabel>How many people work there</InputLabel>
              <Select
                label="How many people work there"
                name="peopleCount"
                value={formData.peopleCount}
                onChange={handleChange}
              >
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="Less than 10">Less than 10</MenuItem>
                <MenuItem value="Less than 50">Less than 50</MenuItem>
                <MenuItem value="Less than 100">Less than 100</MenuItem>
                <MenuItem value="More than 100">More than 100</MenuItem>
              </Select>
              {errors.peopleCount && (
                <Typography variant="caption" color="error">
                  {errors.peopleCount}
                </Typography>
              )}
            </FormControl>
            <FormControl fullWidth margin="normal" error={!!errors.currency}>
              <InputLabel>What currency do you use</InputLabel>
              <Select
                label="What currency do you use"
                name="currency"
                value={formData.currency}
                onChange={handleChange}
              >
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="EUR">EUR</MenuItem>
                <MenuItem value="JPY">JPY</MenuItem>
                <MenuItem value="INR">INR</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
              {errors.currency && (
                <Typography variant="caption" color="error">
                  {errors.currency}
                </Typography>
              )}
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleNext}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Create account"
              )}
            </Button>
          </>
        )}

        {activeStep === 2 && (
          <>
            <Typography variant="h5" sx={{ marginBottom: "2%" }}>
              Great, Now check your inbox!
            </Typography>
            <Typography>
              We've emailed a verification link to: <br />
              <strong>{formData.workEmail}</strong>
            </Typography>
            <Typography variant="body2">
              Simply verify your email to start using Bizflyte.
            </Typography>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<GoogleIcon />}
                href="https://mail.google.com"
                target="_blank"
              >
                Open Gmail
              </Button>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<GoogleIcon />}
                href="https://outlook.live.com"
                target="_blank"
                style={{ marginLeft: "10px" }}
              >
                Open Outlook
              </Button>
            </Box>
            <Typography variant="body2" mt={2}>
              Didn't receive the email? <Link href="#">Resend</Link>
            </Typography>
          </>
        )}
      </Box>
      <LoginDialog
        open={loginDialogOpen}
        onClose={handleCloseLoginDialog}
        setIsLoggedIn={setIsLoggedIn}
        setRole={setRole}
      />
    </div>
  );
};

export default SignUpStep1;
