import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HotelCard from "./HotelCard";
import HotelRoomModel from "./componentshotelroom/HotelRoomModel";
import "./MainContent.css";

const MainContent = ({ sortOption, searchParams }) => {
  const [hotels, setHotels] = useState([]);
  const [sortedHotels, setSortedHotels] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const token = localStorage.getItem("jwt"); // Get the JWT token
        console.log("token=", token);

        let latitude, longitude;

        if (searchParams.coordinates) {
          // Use coordinates from the searchParams
          ({ latitude, longitude } = searchParams.coordinates);
        } else {
          // Fallback to using /location API if coordinates are not provided
          const locationResponse = await fetch(
            `http://104.237.9.131:8080/api/accommodation/location/search/${searchParams.location}`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Include the JWT token
              },
            }
          );
          const locationData = await locationResponse.json();
          console.log("locationData=", locationData);
          latitude = locationData.latitude;
          longitude = locationData.longitude;
        }

        const response = await fetch(
          "http://104.237.9.131:8080/api/accommodation/booking/hotels",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Include the JWT token
            },
            body: JSON.stringify({
              stay: {
                checkIn: searchParams.checkIn,
                checkOut: searchParams.checkOut,
              },
              occupancies: [
                {
                  rooms: 1,
                  adults: searchParams.adults,
                  children: searchParams.children,
                  paxes: searchParams.paxes,
                },
              ],
              geolocation: {
                latitude,
                longitude,
                radius: 30,
                unit: "km",
              },
            }),
          }
        );

        const data = await response.json();
        setHotels(data.hotels.hotels);
      } catch (error) {
        console.error("Error fetching hotels:", error);
      }
    };

    if (searchParams) {
      fetchHotels();
    }
  }, [searchParams]);

  useEffect(() => {
    const sortHotels = (hotels, option) => {
      switch (option) {
        case "Popular":
          return hotels;
        case "Budget":
          return [...hotels].sort(
            (a, b) => parseFloat(a.minRate) - parseFloat(b.minRate)
          );
        case "Premium":
          return [...hotels].sort(
            (a, b) => parseFloat(b.minRate) - parseFloat(a.minRate)
          );
        case "PriceHighest":
          return [...hotels].sort(
            (a, b) => parseFloat(b.maxRate) - parseFloat(a.maxRate)
          );
        case "Ratings":
          return hotels;
        default:
          return hotels;
      }
    };

    setSortedHotels(sortHotels(hotels, sortOption));
  }, [hotels, sortOption]);

  const handleCardClick = (hotel) => {
    setSelectedHotel(hotel);
  };

  const closePopup = () => {
    setSelectedHotel(null);
  };

  const handleBookClick = (room, rate) => {
    navigate("/checkout", {
      state: {
        hotel: selectedHotel,
        room,
        rate,
        searchParams,
      },
    });
  };

  return (
    <div className="main-content-container">
      <div className="hotel-list-container">
        {sortedHotels.map((hotel) => (
          <div className="hotel-card-container" key={hotel.code}>
            <HotelCard hotel={hotel} onCardClick={handleCardClick} />
          </div>
        ))}
      </div>
      {selectedHotel && (
        <HotelRoomModel
          hotel={selectedHotel}
          onClose={closePopup}
          onBookClick={handleBookClick}
        />
      )}
    </div>
  );
};

export default MainContent;
