// src/components/LoginDialog.js
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box,
  Typography,
  Link,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SignUpDialog from "./SignUpDialog";

const LoginDialog = ({ open, onClose, setIsLoggedIn, setRole }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [signUpDialogOpen, setSignUpDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loginData, setLoginData] = useState({ username: "", password: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await fetch("http://104.237.9.131:8080/api/userauth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log("login data=", data);
        localStorage.setItem("jwt", data.jwt);
        // console.log("loginData=", loginData);
        // console.log("username=", loginData.username);
        localStorage.setItem("username", loginData.username);
        setRole(data.roles);
        setIsLoggedIn(true);
        if (data.roles.includes("ROLE_ADMIN")) {
          navigate("/AdminDashBoard");
        } else {
          console.log("I am here");
          navigate("/HotelDashBoard");
        }
        onClose();
      } else {
        alert("Login failed: Please check your credentials.");
      }
    } catch (error) {
      console.error("Login error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSignUpSuccess = () => {
    setSignUpDialogOpen(false);
    setSuccessMessage(
      "Registration Done Successfully! Please proceed for Login"
    );
  };

  const buttonStyle = {
    backgroundColor: "#0054A1",
    color: "#fff",
    padding: "10px 20px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "500",
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Typography variant="h5" align="center">
            Sign In
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email Id"
              name="username"
              autoComplete="email"
              autoFocus
              onChange={handleInputChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
              <Link href="#" variant="body2">
                Forgot Password?
              </Link>
            </Box>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              style={buttonStyle}
              onClick={handleLogin}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Log In"}
            </Button>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="body2">Or</Typography>
              <Button
                variant="outlined"
                fullWidth
                sx={{ mt: 1 }}
                onClick={() => setSignUpDialogOpen(true)}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <SignUpDialog
        open={signUpDialogOpen}
        onClose={() => setSignUpDialogOpen(false)}
        onSignUpSuccess={handleSignUpSuccess}
        buttonStyle={buttonStyle}
      />

      <Dialog
        open={Boolean(successMessage)}
        onClose={() => setSuccessMessage("")}
      >
        <DialogTitle>{successMessage}</DialogTitle>
        <DialogContent>
          <Button
            style={buttonStyle}
            onClick={() => setSuccessMessage("")}
            color="primary"
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoginDialog;
