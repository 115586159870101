import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ActivateSignUp from "./components/ActivateSignup";
import ActiveClients from "./components/ActiveClients";
import AdminDashBoard from "./components/AdminDashBoard";
import ActivationRedirect from "./components/Authentication/ActivationRedirect";
import LoginPage1 from "./components/Authentication/LoginPage1";
import LoginPage2 from "./components/Authentication/LoginPage2";
import SignUpStep1 from "./components/Authentication/SignUpStep1";
import Header from "./components/Header";
import CheckOut from "./components/RoomCheckOut/CheckOut";
import ScrollToTop from "./components/ScrollToTop";
import { AuthProvider } from "./contexts/AuthProvider";
import AirportProvider from "./contexts/AirportProvider";
import FlightResults from "./components/FlightResults";
import BookingHistory from "./components/BookingHistory";
import FlightCheckOut from "./components/FlightCheckOut/FlightCheckOut";
const theme = createTheme({
  typography: {
    fontFamily: "poppins",
  },
});

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    setIsLoggedIn(!!token);
    console.log("isLoggedIn =", isLoggedIn);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <AirportProvider>
          <Router>
            <ScrollToTop />
            <div>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={isLoggedIn ? <Header /> : <SignUpStep1 />}
                />
                <Route path="/checkout" element={<CheckOut />} />
                <Route path="/HotelDashBoard" element={<Header />} />
                <Route path="/AdminDashBoard" element={<AdminDashBoard />} />
                <Route path="/activate-signup" element={<ActivateSignUp />} />
                <Route path="/active-clients" element={<ActiveClients />} />
                <Route path="/discount-coupons" element={<AdminDashBoard />} />
                <Route
                  path="/login/:userId/:activationKey"
                  element={<ActivationRedirect />}
                />
                <Route path="/loginActivated" element={<LoginPage1 />} />
                <Route path="/loginActivated2" element={<LoginPage2 />} />
                <Route path="/flight-results" element={<FlightResults />} />
                <Route path="/booking-history" element={<BookingHistory />} />
                <Route path="/flight-checkout" element={<FlightCheckOut />} />
              </Routes>
            </div>
          </Router>
        </AirportProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
