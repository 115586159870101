// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HotelRomModel {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: #ffffff;
  padding: 20px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  max-height: 90%;
  overflow-y: auto;
  width: 85vw; 
  max-width: none;
  border-radius: 15px;
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  z-index: 999;
}

.demoimage {
  height: 180px;
  width: 500px;
  border-radius: 10px;
}

.locationimage {
  height: 18px;
  width: 18px;
}

.smaller-text {
  font-size: 0.8em;
}

.democardimage {
  height: 100px;
  width: 200px;
}

.img-col{
    max-width: 100%;
    height: auto;
    display: block;
}

.popup-content{
  font-size: 12px;
  margin-top: -15px;
}

.popup-header{
font-size: 12px;
}

.price-info {
  font-size: 14px;
  font-weight: 600;
}

.price-pop-content{
  font-size: 8px;
  margin-top: -15px;
}`, "",{"version":3,"sources":["webpack://./src/components/componentshotelroom/HotelRoomModel.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,aAAa;EACb,yBAAyB;EACzB,aAAa;EACb,6CAA6C;EAC7C,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,0BAA0B;EAC1B,YAAY;AACd;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,cAAc;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;AACA,eAAe;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".HotelRomModel {\r\n  position: fixed;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  z-index: 1000;\r\n  background-color: #ffffff;\r\n  padding: 20px;\r\n  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */\r\n  max-height: 90%;\r\n  overflow-y: auto;\r\n  width: 85vw; \r\n  max-width: none;\r\n  border-radius: 15px;\r\n}\r\n\r\n.blur-background {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-color: rgba(0, 0, 0, 0.5);\r\n  backdrop-filter: blur(2px);\r\n  z-index: 999;\r\n}\r\n\r\n.demoimage {\r\n  height: 180px;\r\n  width: 500px;\r\n  border-radius: 10px;\r\n}\r\n\r\n.locationimage {\r\n  height: 18px;\r\n  width: 18px;\r\n}\r\n\r\n.smaller-text {\r\n  font-size: 0.8em;\r\n}\r\n\r\n.democardimage {\r\n  height: 100px;\r\n  width: 200px;\r\n}\r\n\r\n.img-col{\r\n    max-width: 100%;\r\n    height: auto;\r\n    display: block;\r\n}\r\n\r\n.popup-content{\r\n  font-size: 12px;\r\n  margin-top: -15px;\r\n}\r\n\r\n.popup-header{\r\nfont-size: 12px;\r\n}\r\n\r\n.price-info {\r\n  font-size: 14px;\r\n  font-weight: 600;\r\n}\r\n\r\n.price-pop-content{\r\n  font-size: 8px;\r\n  margin-top: -15px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
