import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./RoomDetails.css";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Container,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import image from "./template-hotel.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import PaymentModal from "../PaymentModal";

const RoomDetails = ({ hotel, room, rate, searchParams }) => {
  const underlineStyle = { textDecoration: "underline" };
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookingDialogOpen, setBookingDialogOpen] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const navigate = useNavigate();
  const detailsRef = useRef(null);
  const firstNameRef = useRef(null);
  const guestsDetailsRef = useRef(null);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [amount, setAmount] = useState(0);

  const nights =
    searchParams && searchParams.checkIn && searchParams.checkOut
      ? Math.ceil(
          (new Date(searchParams.checkOut) - new Date(searchParams.checkIn)) /
            (1000 * 60 * 60 * 24)
        )
      : 0;

  useEffect(() => {
    console.log("rate=", rate);
    if (rate && searchParams) {
      const fullAmount =
        parseFloat(rate.net * nights) +
        (rate.taxes ? parseFloat(rate.taxes.taxes[0].amount) : 0.0);
      setAmount(fullAmount);
    }
  }, [rate, searchParams, nights]);

  const addGuest = () => {
    const childrenDataEntered = guestsData.filter(
      (guest) => guest.type === "CH"
    ).length;
    const adultDataEntered = guestsData.filter(
      (guest) => guest.type === "AD"
    ).length;
    const remainingChildrenTobeEntered =
      searchParams.children - childrenDataEntered;
    const remainingAdultsTobeEntered = searchParams.adults - adultDataEntered;
    if (remainingAdultsTobeEntered === 0 && formData.type === "AD") {
      alert(
        `You are not allowed to enter more adult guests as you have already entered all the adult guests data`
      );
      return;
    }
    if (remainingChildrenTobeEntered === 0 && formData.type === "CH") {
      alert(
        `You are not allowed to enter more children guests as you have already entered all the children guests data`
      );
      return;
    }
    const errors = {};
    Object.keys(formData).forEach((field) => {
      if (
        (field === "firstName" || field === "lastName") &&
        formData[field].trim() === ""
      ) {
        errors[field] = true;
      }
    });

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      alert("Please fill in all required fields.");
      return;
    }
    setGuestsData([...guestsData, { ...formData }]);
    setFormData({
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      type: "AD",
    });
    setValidationErrors({});
    console.log("guestsData=", guestsData);
    if (guestsData.length > 0) {
      console.log("In here line 97");
      guestsDetailsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      guestsDetailsRef.current.focus();
    }
  };

  const removeGuest = (indexToRemove) => {
    setGuestsData(guestsData.filter((_, index) => index !== indexToRemove));
  };
  const validateGuestData = () => {
    const totalGuests = searchParams.adults + searchParams.children;
    if (guestsData.length < totalGuests) {
      const childrenDataEntered = guestsData.filter(
        (guest) => guest.type === "CH"
      ).length;
      const adultDataEntered = guestsData.filter(
        (guest) => guest.type === "AD"
      ).length;
      const remainingChildrenTobeEntered =
        searchParams.children - childrenDataEntered;
      const remainingAdultsTobeEntered = searchParams.adults - adultDataEntered;
      if (
        childrenDataEntered < searchParams.children &&
        adultDataEntered < searchParams.adults
      ) {
        alert(
          `Incomplete details: Please enter remaining ${remainingChildrenTobeEntered} number of Children data 
           and ${remainingAdultsTobeEntered} of adults out of total ${totalGuests} guests.`
        );
      } else if (childrenDataEntered < searchParams.children) {
        alert(
          `Incomplete details: Please enter remaining ${remainingChildrenTobeEntered} number of Children data`
        );
      } else {
        alert(
          `Incomplete details: Please enter remaining ${remainingAdultsTobeEntered} number of adult guests`
        );
      }

      detailsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      firstNameRef.current.focus();
      return false;
    }
    return true;
  };

  const handleClick = (newRating) => {
    setRating(newRating);
  };

  const handleMouseEnter = (newHover) => {
    setHover(newHover);
  };

  const handleMouseLeave = () => {
    setHover(rating);
  };

  const currencySymbols = {
    USD: "$",
    EUR: "€",
    JPY: "¥",
    GBP: "£",
    AUD: "AU$",
    CAD: "CA$",
  };

  const getCurrencySymbol = ({ hotel }) => {
    const symbol = currencySymbols[hotel.currency];
    return symbol;
  };

  const [guestsData, setGuestsData] = useState([]);

  useEffect(() => {
    // Scroll and focus only if guestsData.length > 0
    if (guestsData.length > 0) {
      guestsDetailsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      guestsDetailsRef.current.focus();
    }
  }, [guestsData]);
  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    type: "AD", // Default to "Adult"
  });

  const [validationErrors, setValidationErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  if (!hotel || !room || !rate || !searchParams) {
    return <div>Return to Home page</div>;
  }

  const handleOpenModal = () => {
    if (!validateGuestData()) return;
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const injectCSS = () => {
    const link = document.createElement("link");
    link.href = "./razorpay-custom.css";
    link.type = "text/css";
    link.rel = "stylesheet";
    document.head.appendChild(link);
  };

  const createOrder = async () => {
    const response = await fetch(
      `http://104.237.9.131:8080/api/accommodation/api/payment/createOrder?amount=1&currency=INR`,
      // `http://104.237.9.131:8080/api/payment/createOrder?amount=${Math.round(
      //   amount
      // )}&currency=INR`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return await response.json();
  };

  const verifyPayment = async (orderId, paymentId, signature) => {
    console.log("orderId=", orderId);
    console.log("paymentId=", paymentId);
    console.log("signature=", signature);
    const response = await fetch(
      `http://104.237.9.131:8080/api/accommodation/api/payment/verifyPayment?orderId=${orderId}&paymentId=${paymentId}&signature=${signature}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return await response.text();
  };
  const handlePayment = async (paymentMethod) => {
    if (paymentMethod === "btc") {
      // Execute BTC-specific booking flow
      setLoading(true);
      try {
        const bookingHotelRequest = {
          holder: {
            name: formData.firstName || "HolderFirstName",
            surname: formData.lastName || "HolderLastName",
          },
          rooms: [
            {
              rateKey: rate.rateKey, // Dynamic rateKey from the rate object
              paxes: guestsData.map((guest) => ({
                roomId: 1,
                type: guest.type,
                name: guest.firstName,
                surname: guest.lastName,
              })),
            },
          ],
          clientReference: "IntegrationAgency",
          remark: "Booking remarks are to be written here.",
          tolerance: 2,
        };
        const userName = localStorage.getItem("username");
        const bookingRequest = {
          userName: userName,
          bookingHotelRequest: bookingHotelRequest,
        };
        console.log("bookingRequest=", bookingRequest);
        const bookingResponse = await fetch(
          "http://104.237.9.131:8080/api/accommodation/booking/book-hotel",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            body: JSON.stringify(bookingRequest),
          }
        );

        if (bookingResponse.status === 200) {
          const responseData = await bookingResponse.json();
          const bookingReference = responseData.booking.reference;
          setBookingDetails({
            reference: bookingReference,
            guests: guestsData,
            hotelName: hotel.name,
            checkIn: searchParams.checkIn,
            checkOut: searchParams.checkOut,
            rooms: searchParams.rooms,
          });
          setBookingDialogOpen(true);
        } else {
          alert("Booking failed, please try again");
        }
      } catch (error) {
        console.error("Booking error:", error);
        alert("An error occurred during booking.");
      } finally {
        setLoading(false);
      }
      return;
    }
    console.log("amount is =", amount);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const order = await createOrder();

    const options = {
      key: "rzp_test_dRH92j43X0Pcxi",
      amount: Math.round(amount * 100), // Amount in paise
      currency: hotel.currency,
      name: "Bizflyte",
      description: "Test Hotel Checkout Transaction",
      order_id: order.id,
      handler: async function (response) {
        const result = await verifyPayment(
          response.razorpay_order_id,
          response.razorpay_payment_id,
          response.razorpay_signature
        );
        alert(result);
        if (result === "Payment is valid") {
          setLoading(true);
          // call the /accommodation/booking/book-hotel api after constructing the Proper BookingHotelRequestDTO
          // from existing data such as rate

          // Hardcoded booking request DTO with dynamic rateKey
          try {
            const bookingHotelRequest = {
              holder: {
                name: formData.firstName || "HolderFirstName",
                surname: formData.lastName || "HolderLastName",
              },
              rooms: [
                {
                  rateKey: rate.rateKey, // Dynamic rateKey from the rate object
                  paxes: guestsData.map((guest) => ({
                    roomId: 1,
                    type: guest.type,
                    name: guest.firstName,
                    surname: guest.lastName,
                  })),
                },
              ],
              clientReference: "IntegrationAgency",
              remark: "Booking remarks are to be written here.",
              tolerance: 2,
            };
            const userName = localStorage.getItem("username");
            const bookingRequest = {
              userName: userName,
              bookingHotelRequest: bookingHotelRequest,
            };
            console.log("bookingRequest=", bookingRequest);
            const bookingResponse = await fetch(
              "http://104.237.9.131:8080/api/accommodation/booking/book-hotel",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
                body: JSON.stringify(bookingRequest),
              }
            );

            if (bookingResponse.status === 200) {
              const responseData = await bookingResponse.json();
              const bookingReference = responseData.booking.reference;
              // alert(
              //   "Payment is success, Booking is confirmed with booking id: " +
              //     bookingReference
              // );
              setBookingDetails({
                reference: bookingReference,
                guests: guestsData,
                hotelName: hotel.name,
                checkIn: searchParams.checkIn,
                checkOut: searchParams.checkOut,
                rooms: searchParams.rooms,
              });
              setBookingDialogOpen(true);
              // navigate("/HotelDashBoard");
            } else {
              alert("Booking failed, please try again");
            }
          } catch (error) {
            console.error("Booking error:", error);
            alert("An error occurred during booking.");
          } finally {
            setLoading(false);
          }
        }
      },
      prefill: {
        name: "Aditya Ducima",
        email: "your.email@example.com",
        contact: "9999999999",
      },
      theme: {
        color: "#0054a1",
      },
      modal: {
        ondismiss: function () {
          console.log("Checkout form closed");
        },
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    // Resizing the modal after it has opened
    setTimeout(() => {
      const modal = document.querySelector(".razorpay-checkout-frame");
      if (modal) {
        if (modal) {
          modal.style.height = "90%";
          modal.style.width = "350px";
          modal.style.top = "50%";
          modal.style.left = "50%";
          modal.style.transform = "translate(-50%, -50%)";
        }
      }
    }, 1000); // Adjust the timeout if necessary
  };

  const symbol = getCurrencySymbol({ hotel });
  const handleCloseBookingDialog = () => {
    setBookingDialogOpen(false);
    navigate("/HotelDashBoard");
  };
  return (
    <Container>
      <div className="row mt-3">
        <div className="col-lg-8">
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Typography fontSize={20}>Review your Booking</Typography>
                  <Typography fontSize={24} fontWeight={600} marginTop={0.5}>
                    {hotel.name}
                  </Typography>
                  <Typography fontSize={16} lineHeight={1.2}>
                    {hotel.zoneName}
                  </Typography>
                  <Typography fontSize={14} lineHeight={1.2}>
                    {hotel.destinationName}
                  </Typography>
                  <Typography>
                    <div class="star-rating mb-2">
                      {[...Array(5)].map((star, index) => {
                        const starClass =
                          index < rating
                            ? "on"
                            : index < hover
                            ? "hover"
                            : "off";

                        return (
                          <span
                            key={index}
                            className={`star ${starClass}`}
                            onClick={() => handleClick(index + 1)}
                            onMouseEnter={() => handleMouseEnter(index + 1)}
                            onMouseLeave={handleMouseLeave}
                          >
                            ★
                          </span>
                        );
                      })}
                      <span className="rating-content">
                        {rating > 0 ? rating : null}
                      </span>
                    </div>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src={image}
                    alt="hotel"
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid spacing={1}>
                <div className="row border-check-cont">
                  <div className="col-md-3 column check-border">
                    <label htmlFor="checkIn" className="check-label">
                      Check-In
                    </label>
                    <br />
                    {searchParams.checkIn}
                  </div>
                  <div className="col-md-3 column check-border">
                    <label htmlFor="checkOut" className="check-label">
                      Check-Out
                    </label>
                    <br />
                    {searchParams.checkOut}
                  </div>
                  <div className="col-md-3 column check-border">
                    <label htmlFor="rooms" className="check-label">
                      Rooms & Guests
                    </label>
                    <br />
                    {searchParams.rooms} <span>Room</span> {searchParams.guests}{" "}
                    <span>Guests</span>
                  </div>
                  <div className="col-md-3 column check-border check-border-last">
                    <label htmlFor="night" className="check-label">
                      Nights
                    </label>
                    <br />
                    {nights}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="room-type-text">{room.name}</div>
                    <p className="room-categories">
                      <small>
                        {rate.adults} adults & {rate.children} children
                      </small>
                    </p>
                    <ul className="room-list">
                      <li>
                        <small>{rate.boardName}</small>
                      </li>
                      <li>
                        <small>
                          <span style={underlineStyle}>Non-Refundable</span> |
                          Refund is not applicable for booking{" "}
                          <span style={underlineStyle}>
                            Cancellation policy details
                          </span>
                        </small>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <div className="text-end">
                      <a href="">See Conclusion</a>
                    </div>
                  </div>
                </div>
              </Grid>
              <div className="row mb-3 mt-3">
                <div className="important-info colored-text">
                  <div className="col-lg-10 d-flex flex-column">
                    {/* <Grid
                      spacing={1}
                    > */}
                    <div className="room-type-text color-orange">
                      Important Information
                    </div>
                    <ul className="room-list">
                      <li>
                        <small>
                          Passport, Aadhar, Driving License and Govt. ID are
                          accepted as ID proof(s)
                        </small>
                      </li>
                      <li>
                        <small>Pets are not allowed</small>
                      </li>
                      <li>
                        <small>
                          Smoking within the premises is not allowed
                        </small>
                      </li>
                    </ul>
                    {/* </Grid> */}
                  </div>
                  <div className="col-lg-2 d-flex flex-column text-end">
                    <a href="">View all</a>
                  </div>
                </div>
              </div>
              <Grid>
                <div className="room-type-text">Cancellation Policy</div>
                {/* <ul className="cancel-policy">
                  <li className="red-text">
                    <small>Cancel up to Tue 2 Jul 2024</small>
                  </li>
                  <li className="red-text">
                    <small>
                      The full cost of the booking will be refunded to you. No
                      cancellation change applied
                    </small>
                  </li>
                  <li className="red-text">
                    <small>Cancel on or after Wed 3 Jul 2024</small>
                  </li>
                  <li className="red-text">
                    <small>
                      A cancellation charge of {symbol}
                      {rate.cancellationPolicies.amount} will apply
                    </small>
                  </li>
                </ul> */}
                {rate.cancellationPolicies && (
                  <Box sx={{ marginTop: "5px", fontSize: "14px" }}>
                    {/* <Typography variant="subtitle2">
                      Cancellation Policies:
                    </Typography> */}
                    <Typography variant="body2">
                      {rate.cancellationPolicies.map((policy, index) => (
                        <li key={index}>
                          Amount: ${policy.amount}, Effective from:{" "}
                          {new Date(policy.from).toLocaleString()}
                        </li>
                      ))}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid>
                <div className="check-policy mt-2 mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="myCheckbox"
                    name="myCheckbox"
                    onChange={handleChange}
                  />

                  <label className="form-check-label">
                    I accept the cancellation policy for this booking and the{" "}
                    <a href="">Terms & Conditions.</a>
                  </label>
                </div>
              </Grid>
            </CardContent>
          </Card>
          {/* <div className="mt-3" ref={detailsRef}> */}
          <Card ref={detailsRef} sx={{ marginTop: "2%", marginBottom: "2%" }}>
            <CardContent>
              <Typography
                variant="h6"
                className="mt-3"
                sx={{ marginTop: "2%", marginBottom: "2%" }}
              >
                Fill Your Details
              </Typography>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      select
                      label="Type"
                      name="type"
                      value={formData.type}
                      onChange={handleChange}
                      fullWidth
                    >
                      <MenuItem value="AD">Adult</MenuItem>
                      <MenuItem value="CH">Child</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="First Name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      fullWidth
                      required
                      inputRef={firstNameRef}
                      error={validationErrors.firstName}
                      helperText={
                        validationErrors.firstName
                          ? "First name is required"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      fullWidth
                      required
                      error={validationErrors.lastName}
                      helperText={
                        validationErrors.lastName ? "Last name is required" : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button onClick={addGuest} variant="outlined">
                      + Add Guest
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
          {/* </div> */}
          {guestsData.length > 0 && (
            <Card sx={{ marginBottom: "2%" }}>
              <CardContent>
                <Typography variant="h6" tabIndex="-1" ref={guestsDetailsRef}>
                  You have entered following Guests Details
                </Typography>
                {guestsData.map((guest, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <Typography variant="body2" style={{ marginRight: "16px" }}>
                      Name: {guest.firstName} {guest.lastName} <br />
                      Type: {guest.type === "AD" ? "Adult" : "Child"}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => removeGuest(index)}
                      style={{ marginLeft: "auto" }}
                    >
                      Remove
                    </Button>
                  </div>
                ))}
              </CardContent>
            </Card>
          )}
        </div>

        <div className="col-lg-4">
          <div className="mt-0">
            <Card>
              <CardContent>
                <div class="d-flex flex-column">
                  <div class="mb-3">
                    <div className="price-breakup">Price Breakup</div>
                    <div className="price-content">
                      1 Room x {nights} Nights{" "}
                      <span className="currency">
                        {symbol}
                        {rate.net * nights}
                      </span>
                    </div>
                    <div className="price-type">Base price</div>
                    <div className="price-content">
                      Total Discount:
                      <span className="currency">{symbol}0.00</span>
                    </div>
                    <div className="price-content">
                      Price after Discounts:
                      <span className="currency">
                        {symbol}
                        {rate.net * nights}
                      </span>
                    </div>
                    <div className="price-content">
                      Hotel Taxes:
                      <span className="currency">
                        {symbol}
                        {rate.taxes ? rate.taxes.taxes[0].amount : "0.00"}
                      </span>
                    </div>
                    <div class="donate-checkbox form-check mb-3">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="donate"
                      />
                      <label class="form-check-label" for="donate">
                        Donate $5 to build resilient travel destination{" "}
                        <a href="#">T&amp;C Apply</a>
                      </label>
                    </div>
                    <div className="total-price">
                      <strong>Total Amount to be paid:</strong>
                      <span>
                        <strong>
                          {symbol}
                          {amount}
                        </strong>
                      </span>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="mt-3">
            <Card>
              <CardContent>
                <div className="">
                  <div class="coupon-codes mb-3 cashback">
                    <div className="price-breakup">Coupon Codes</div>
                    <div className="form-check mt-2">
                      <input type="radio" className="form-check-input" />
                      <label className="form-check-label color-cashback">
                        CASHBACK
                      </label>
                    </div>
                    <div className="price-cashback">
                      Congratulations!! Cashback of $50 will be credited to your
                      card within 3 days of booking
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div>
            {/* <Card>
            <CardContent> */}
            {loading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                fullWidth
              >
                Checkout
              </Button>
            )}
            <button class="btn btn-outline-secondary btn-cart">
              Add to Cart
            </button>
            {/* </CardContent>
          </Card> */}
          </div>
        </div>
      </div>
      <PaymentModal
        open={modalOpen}
        onClose={handleCloseModal}
        amount={amount}
        handlePayment={handlePayment}
        checkrateKey={rate.rateKey}
        perDayRent={rate.net}
      />
      <Dialog open={bookingDialogOpen} onClose={handleCloseBookingDialog}>
        <DialogTitle>Booking Confirmed</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Booking ID: <strong>{bookingDetails?.reference}</strong>
          </Typography>
          <Typography variant="body1">
            Hotel: <strong>{bookingDetails?.hotelName}</strong>
          </Typography>
          <Typography variant="body1">
            Rooms: <strong>{bookingDetails?.rooms}</strong>
          </Typography>
          <Typography variant="body1">
            Dates:{" "}
            <strong>
              {bookingDetails?.checkIn} to {bookingDetails?.checkOut}
            </strong>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "10px" }}>
            Guests:
          </Typography>
          {bookingDetails?.guests.map((guest, index) => (
            <Typography key={index} variant="body2">
              - {guest.type === "AD" ? "Adult" : "Child"}: {guest.firstName}{" "}
              {guest.lastName}
            </Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBookingDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default RoomDetails;
