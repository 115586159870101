// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-bar {
  background-color: #24A0ED;
  /* height: 319px; */
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-bar .container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-bar .container .links {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.header-bar .container img {
  /* width: 424px; */
  width: 20%;
  /* height: 73px; */
  height: auto;
  opacity: 0.9;
}

.header-bar .container h3 {
  color: #FFFFFF;
  font-size: 64px;
  line-height: 96px;
  font-weight: 275;
  margin-top: -75px;
}

.header-bar .container .tabs-container {
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 8px;
  position: absolute;
  bottom: 0;
  padding: 10px 0;
}

.flight-src {
  margin-top: -20px;
  margin-left: -240px;

}

.tab-content label{
  font-family: 'Noto Sans', sans-serif;
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;;AAErB;;AAEA;EACE,oCAAoC;EACpC,0BAA0B;AAC5B","sourcesContent":[".header-bar {\r\n  background-color: #24A0ED;\r\n  /* height: 319px; */\r\n  height: 40vh;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.header-bar .container {\r\n  position: relative;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n}\r\n\r\n.header-bar .container .links {\r\n  display: flex;\r\n  gap: 20px;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.header-bar .container img {\r\n  /* width: 424px; */\r\n  width: 20%;\r\n  /* height: 73px; */\r\n  height: auto;\r\n  opacity: 0.9;\r\n}\r\n\r\n.header-bar .container h3 {\r\n  color: #FFFFFF;\r\n  font-size: 64px;\r\n  line-height: 96px;\r\n  font-weight: 275;\r\n  margin-top: -75px;\r\n}\r\n\r\n.header-bar .container .tabs-container {\r\n  background-color: #FFFFFF;\r\n  width: 100%;\r\n  border-radius: 8px;\r\n  position: absolute;\r\n  bottom: 0;\r\n  padding: 10px 0;\r\n}\r\n\r\n.flight-src {\r\n  margin-top: -20px;\r\n  margin-left: -240px;\r\n\r\n}\r\n\r\n.tab-content label{\r\n  font-family: 'Noto Sans', sans-serif;\r\n  text-transform: capitalize;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
