import GoogleIcon from "@mui/icons-material/Google";
import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FlightImage from "../../assets/flight.png";
import logo from "../../assets/logo/logoSignUp.png";
import AuthContext from "../../contexts/AuthProvider";
import LoginDialog from "../LoginDialog";
import "./LoginPage1.css";

const LoginPage2 = () => {
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const { isLoggedIn, setIsLoggedIn, setRole, role } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleCloseLoginDialog = () => {
    setLoginDialogOpen(false);
  };

  const handleLoginClick = () => {
    setLoginDialogOpen(true);
  };

  useEffect(() => {
    if (isLoggedIn) {
      console.log("I am here in LoginPage1, role=", role);
      if (role.includes("ROLE_ADMIN")) {
        navigate("/AdminDashBoard");
      } else {
        // will add more role based routing to different pages on login
        navigate("/");
      }
    }
  }, []);

  return (
    <div className="login-page">
      <Box className="login-left">
        <img src={FlightImage} alt="Flight" className="login-image-overlay" />
      </Box>
      <Box className="login-right">
        <Typography sx={{ margin: "5%" }} variant="h6" className="login-title">
          <img src={logo} alt="Logo" width="55%" />
        </Typography>

        <Typography variant="h5">
          Welcome Back , you have successfuly set your password.
          <br /> Sign in
        </Typography>
        <Typography variant="body2">
          You have created a <strong> BizFlyte</strong> account{" "}
        </Typography>
        <Typography variant="body1">Please sign in with your email.</Typography>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLoginClick}
        >
          Get Started
        </Button>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<GoogleIcon />}
          fullWidth
          style={{ marginTop: "10px" }}
        >
          Sign in with Google
        </Button>
      </Box>
      <LoginDialog
        open={loginDialogOpen}
        onClose={handleCloseLoginDialog}
        setIsLoggedIn={setIsLoggedIn}
        setRole={setRole}
      />
    </div>
  );
};

export default LoginPage2;
