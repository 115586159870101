import React, { useEffect, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import FlightDetailsCard from "./FlightDetailsCard";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const BookingDialog = ({
  bookingDialogOpen,
  handleCloseBookingDialog,
  bookingReference,
  flightSegments,
}) => {
  const dialogRef = useRef();
  useEffect(() => {
    console.log("BookingDialog called");
  }, []);

  const handleDownload = async () => {
    const dialogElement = dialogRef.current;

    if (dialogElement) {
      const originalStyle = dialogElement.getAttribute("style") || "";

      dialogElement.style.maxHeight = "none";
      dialogElement.style.overflow = "visible";
      dialogElement.style.height = "auto";

      const canvas = await html2canvas(dialogElement, {
        scale: 2,
        useCORS: true,
      });

      dialogElement.setAttribute("style", originalStyle);

      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("l", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const imgWidth = pdfWidth;
      const imgHeight = (canvas.height * pdfWidth) / canvas.width;

      if (imgHeight <= pdfHeight) {
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      } else {
        let heightLeft = imgHeight;
        let position = 0;

        while (heightLeft > 0) {
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pdfHeight;
          if (heightLeft > 0) {
            pdf.addPage();
            position = -pdfHeight + heightLeft;
          }
        }
      }

      pdf.save(`BookingDetails_${bookingReference || "download"}.pdf`);
    }
  };

  return (
    <Dialog
      open={bookingDialogOpen}
      onClose={handleCloseBookingDialog}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Booking Confirmed</Typography>
          <Button
            onClick={handleDownload}
            color="primary"
            variant="contained"
            size="small"
          >
            Download
          </Button>
        </Box>
      </DialogTitle>

      <DialogContent
        ref={dialogRef}
        sx={{
          overflowY: "auto",
          maxHeight: "70vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Booking Confirmed with Booking ID: <strong>{bookingReference}</strong>
        </Typography>
        <Box
          sx={{
            width: "100%",
            margin: "0 auto",
          }}
        >
          <FlightDetailsCard flightSegments={flightSegments} />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseBookingDialog} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingDialog;
