import React, { useState } from "react";
import "./HotelRoomModel.css";
import {
  styled,
  IconButton,
  Box,
  Paper,
  Rating,
  Link,
  Card,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  Button,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShareIcon from "@mui/icons-material/Share";
import img from "./Room.jpg";
import imglo from "./location.png";

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  marginTop: "0px",
  overflowX: "hidden",
}));

const staticRoomDetails = {
  size: "210 sq.ft",
  view: "City View",
  beds: "1 Queen bed or 2 Twin Bed(s)",
  amenities: [
    "Interconnected Room",
    "Bathroom",
    "24-hour In-room Dining",
    "Housekeeping",
    "Laundry Service",
    "Air Conditioning",
  ],
  additional: [
    "Complimentary Early Check in",
    "Free Welcome Drink on Arrival",
    "Free Breakfast",
    "Non-Refundable",
  ],
};

const RoomCard = ({ room, rate, onSelectRoom }) => {
  const details = room.details || staticRoomDetails;
  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: 2,
        display: "flex",
        alignItems: "center",
        padding: "10px",
        height: "auto",
        margin: "20px auto",
      }}
    >
      <img
        src={img}
        alt="Room"
        style={{
          width: "30%",
          height: "70%",
          objectFit: "cover",
          borderRadius: "8px",
        }}
      />
      <CardContent
        sx={{
          flex: "1 0 auto",
          paddingLeft: "20px",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Box
          sx={{ position: "absolute", top: 0, right: 0, textAlign: "right" }}
        >
          <Typography variant="h6">US ${rate.net}</Typography>
          <Typography variant="body2" color="text.secondary">
            +$50 Taxes & Fees / per night
          </Typography>
        </Box>
        <Typography variant="body1">{`${rate.boardName}`} </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px 0",
          }}
        >
          {/* <Box sx={{ width: "50%" }}>
            <Typography variant="body2" component="ul">
              {details.amenities.map((amenity, index) => (
                <li key={index}>{amenity}</li>
              ))}
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <Typography variant="body2" component="ul">
              {details.additional.map((add, index) => (
                <li key={index}>{add}</li>
              ))}
            </Typography>
          </Box> */}
          {rate.cancellationPolicies && (
            <Box sx={{ marginTop: "5px" }}>
              <Typography variant="subtitle2">
                Cancellation Policies:
              </Typography>
              <Typography variant="body2">
                {rate.cancellationPolicies.map((policy, index) => (
                  <li key={index}>
                    Amount: ${policy.amount}, Effective from:{" "}
                    {new Date(policy.from).toLocaleString()}
                  </li>
                ))}
              </Typography>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "auto",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => onSelectRoom(room, rate)}
            sx={{ marginBottom: "10px" }}
          >
            Select Room
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

const HotelRoomModel = ({ hotel, onClose, onBookClick }) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSelectRoom = (room, rate) => {
    onBookClick(room, rate);
    onClose();
  };

  return (
    <>
      <div className="blur-background" onClick={onClose}></div>
      <div className="HotelRomModel">
        <IconButton
          onClick={onClose}
          style={{ position: "absolute", top: 0, left: 0 }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            marginTop: "0px",
            marginLeft: "10px",
          }}
        >
          <img className="demoimage" src={img} alt="Room" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              marginTop: "2px",
              marginLeft: "20px",
            }}
          >
            <p>
              <b>{hotel.name}</b>
              <br />
              <span>
                {hotel.destinationName}, {hotel.zoneName}
              </span>
              <br />
              <span className="smaller-text">{hotel.categoryName}</span>
            </p>
            <Box sx={{ display: "flex" }}>
              <Rating
                name="size-medium"
                value={hotel.rating}
                size="small"
                readOnly
              />
              <p className="smaller-text">
                {hotel.reviewsCount
                  ? `Very Good (${hotel.reviewsCount} reviews)`
                  : "No reviews"}
                <span>
                  <Link href="#" style={{ marginLeft: "5px" }}>
                    view all reviews
                  </Link>
                </span>
              </p>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img className="locationimage" src={imglo} alt="Location" />
              <span className="smaller-text" style={{ marginRight: "5px" }}>
                {hotel.zoneName}
              </span>
              <Link className="smaller-text" href="#">
                see on map
              </Link>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "15px" }}></Box>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            marginTop: "10px",
            fontSize: "smaller",
            marginLeft: "10px",
            paddingRight: "10px",
            boxSizing: "border-box",
          }}
        >
          <p>Rates</p>
          <p>Overview</p>
          <p>Reviews</p>
          <p>Location</p>
          <p>
            <Switch defaultChecked size="small" />
            Breakfast included
          </p>
          <p style={{ marginLeft: "auto", marginTop: "4px" }}>
            <ShareIcon />
            <span>Share this property</span>
          </p>
        </Box>
        <DemoPaper variant="elevation" sx={{ width: "80vw" }}>
          <Box sx={{ display: "flex", gap: "0px" }}></Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              fontSize: "smaller",
              marginLeft: "10px",
              marginTop: "13px",
              paddingRight: "10px",
              boxSizing: "border-box",
            }}
          >
            <Typography sx={{ width: "25%", fontWeight: "bold" }}>
              Room Type
            </Typography>
            <Typography sx={{ width: "25%", fontWeight: "bold" }}>
              Board
            </Typography>
            <Typography sx={{ width: "25%", fontWeight: "bold" }}>
              Avg/Night
            </Typography>
            <Typography sx={{ width: "25%", fontWeight: "bold" }}>
              Total Price
            </Typography>
          </Box>
          {hotel.rooms.map((room, index) => (
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleAccordionChange(`panel${index}`)}
              key={room.code}
              sx={{ width: "100%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
              >
                <Typography sx={{ width: "25%" }}>{room.name}</Typography>
                <Typography sx={{ width: "25%", color: "text.secondary" }}>
                  {room.rates[0].boardName}
                </Typography>
                <Typography sx={{ width: "25%", color: "text.primary" }}>
                  US ${room.rates[0].net}
                </Typography>
                <Typography sx={{ width: "25%", color: "text.primary" }}>
                  US ${room.rates[0].net}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {room.rates.map((rate, rateIndex) => (
                  <RoomCard
                    key={rate.rateKey}
                    room={room}
                    rate={rate}
                    onSelectRoom={handleSelectRoom}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </DemoPaper>
      </div>
    </>
  );
};

export default HotelRoomModel;
