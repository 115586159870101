import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const ActivationRedirect = () => {
  const { userId, activationKey } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const response = await axios.get(
          `http://104.237.9.131:8080/api/userauth/verify/${userId}/${activationKey}`
        );

        localStorage.setItem("jwt", response.data.jwt);
        navigate("/loginActivated");
      } catch (error) {
        console.error("Activation failed:", error);
        navigate("/activationFailed");
      }
    };

    verifyUser();
  }, [userId, activationKey, navigate]);

  return <div>Activating...</div>;
};

export default ActivationRedirect;
