import React, { useContext } from "react";
import { Card, CardContent, Typography, Box, Grid } from "@mui/material";
import planeImage from "../../assets/plane.png";
import flightAirlineImg from "../../assets/flightAirline.png";
import vectorImage from "../../assets/vectorImage.png";
import { AirportContext } from "../../contexts/AirportProvider";

const FlightDetailsCard = ({ flightSegments }) => {
  const { airportList } = useContext(AirportContext);
  const getAirportDetails = (code) => {
    return airportList.find((airport) => airport.airportCode === code) || {};
  };
  const formatDuration = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  const calculateTotalJourneyDuration = (segments) => {
    return segments.reduce((totalDuration, segment) => {
      const departureTime = new Date(segment.DepartureDateTime);
      const arrivalTime = new Date(segment.ArrivalDateTime);
      const segmentDuration = (arrivalTime - departureTime) / (1000 * 60); // Convert milliseconds to minutes
      return totalDuration + segmentDuration;
    }, 0);
  };
  const calculateTotalJourneyDurationForSegment = (segment) => {
    const departureTime = new Date(segment.DepartureDateTime);
    const arrivalTime = new Date(segment.ArrivalDateTime);
    const segmentDuration = (arrivalTime - departureTime) / (1000 * 60); // Convert milliseconds to minutes
    return segmentDuration;
  };
  const constructAirlinesForSegments = (segments) => {
    const reducedString = segments.reduce((airlines, segment) => {
      const segmentAirline =
        segment.OperatingAirline.Code +
        "-" +
        segment.OperatingAirline.FlightNumber +
        "(" +
        segment.OperatingAirline.airlineName +
        ")" +
        ",";
      return segmentAirline + airlines;
    }, "");
    if (reducedString.endsWith(",")) {
      return reducedString.slice(0, -1);
    }
    return reducedString;
  };

  return (
    <>
      {/* Flight Overview Card */}
      <Card sx={{ marginBottom: "2%" }}>
        <CardContent>
          <Grid
            spacing={1}
            container
            direction="row"
            sx={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Grid item xs={1}>
              <img
                src={planeImage}
                alt="plane"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h6">
                <strong>{` ${
                  getAirportDetails(
                    flightSegments[0].DepartureAirportLocationCode
                  ).cityName
                } (${flightSegments[0].DepartureAirportLocationCode})   -> ${
                  getAirportDetails(
                    flightSegments[flightSegments.length - 1]
                      .ArrivalAirportLocationCode
                  ).cityName
                } (${
                  flightSegments[flightSegments.length - 1]
                    .ArrivalAirportLocationCode
                })`}</strong>
              </Typography>

              <Typography variant="body2" color="text.secondary">
                {new Date(
                  flightSegments[0].DepartureDateTime
                ).toLocaleDateString()}{" "}
                •{" "}
                {flightSegments.length > 1
                  ? `${flightSegments.length - 1} Stop(s)`
                  : "Non-stop"}{" "}
                •{" "}
                {formatDuration(calculateTotalJourneyDuration(flightSegments))}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card
        variant="elevation"
        elevation={3}
        //   sx={{ marginBottom: "16px", padding: "16px", display: "flex" }}
        sx={{ maxWidth: "100%", marginBottom: "2%" }}
      >
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            //   alignItems="center"
            sx={{ height: "100%", width: "100%" }}
          >
            <Box sx={{ flex: "20%" }}>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <img
                    src={flightAirlineImg}
                    alt="airline"
                    style={{ width: 20, height: 20, marginRight: 8 }}
                  />
                  <Typography variant="subtitle1" fontWeight="bold">
                    {constructAirlinesForSegments(flightSegments)}
                  </Typography>
                </Box>
                <Box>
                  <img
                    src={vectorImage}
                    alt="airline"
                    style={{ width: 20, height: 20, marginRight: 8 }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              sx={{ flex: "60%" }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                sx={{ flex: "80%" }}
              >
                {flightSegments.map((segment, index) => (
                  <Box
                    key={index}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      marginBottom: "2%",
                      backgroundColor: "#FEE69040",
                      borderRadius: "5px",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ flex: "33%", marginLeft: "1%" }}
                    >
                      <Typography variant="body2" color="textSecondary">
                        {new Date(segment.DepartureDateTime).toLocaleString(
                          "en-US",
                          {
                            weekday: "short",
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </Typography>
                      <Typography variant="h6">
                        {new Date(segment.DepartureDateTime).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </Typography>
                      <Typography variant="body2">
                        {
                          getAirportDetails(
                            segment.DepartureAirportLocationCode
                          ).cityName
                        }{" "}
                        ({segment.DepartureAirportLocationCode})
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ flex: "33%", marginLeft: "1%" }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography variant="body4">{`${segment.OperatingAirline.Code}-${segment.OperatingAirline.FlightNumber}`}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {formatDuration(
                            calculateTotalJourneyDurationForSegment(segment)
                          )}
                        </Typography>
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        // justifyContent="center"
                      >
                        <Box
                          sx={{
                            height: "1px",
                            backgroundColor: "#C8C8C8",
                            flex: 1,
                            marginRight: "8px",
                          }}
                        />
                        <img
                          src={planeImage}
                          alt="plane"
                          style={{ width: "24px", height: "24px" }}
                        />
                        <Box
                          sx={{
                            height: "1px",
                            backgroundColor: "#C8C8C8",
                            flex: 1,
                            marginLeft: "8px",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ flex: "33%", marginLeft: "3%" }}
                    >
                      <Typography variant="body2" color="textSecondary">
                        {new Date(segment.ArrivalDateTime).toLocaleString(
                          "en-US",
                          {
                            weekday: "short",
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </Typography>
                      <Typography variant="h6">
                        {new Date(segment.ArrivalDateTime).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </Typography>
                      <Typography variant="body2">
                        {
                          getAirportDetails(segment.ArrivalAirportLocationCode)
                            .cityName
                        }{" "}
                        ({segment.ArrivalAirportLocationCode})
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              sx={{ flex: "20%", marginTop: "2%" }}
            >
              <Typography variant="body2" color="textSecondary">
                100 seats remaining
              </Typography>
              <Typography variant="body2" color="warning.main">
                Partially Refundable
              </Typography>
              <Typography variant="body2" color="warning.main">
                View Flight Details
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default FlightDetailsCard;
