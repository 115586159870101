// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sort-container {
    font-size: 14px;
    display: flex;
    align-items: self-start;
    /* flex-direction: column;
    margin-left: 110px; */
}

.tab-container {
    display: flex;
    align-items: self-start;
    /* /* flex-direction: column; */
    margin-left: 80px;
}

.sort {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0 20px;
    flex: 1 1;
    max-width: 820px;
}

.Mui-selected {
    color: #1976d2 !important;
}

.Mui-selected:focus,  .Mui-selected:active{
background: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/HotelContent.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB;yBACqB;AACzB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,+BAA+B;IAC/B,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,cAAc;IACd,SAAO;IACP,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;AACA,2BAA2B;AAC3B","sourcesContent":[".sort-container {\r\n    font-size: 14px;\r\n    display: flex;\r\n    align-items: self-start;\r\n    /* flex-direction: column;\r\n    margin-left: 110px; */\r\n}\r\n\r\n.tab-container {\r\n    display: flex;\r\n    align-items: self-start;\r\n    /* /* flex-direction: column; */\r\n    margin-left: 80px;\r\n}\r\n\r\n.sort {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding: 0;\r\n    margin: 0 20px;\r\n    flex: 1;\r\n    max-width: 820px;\r\n}\r\n\r\n.Mui-selected {\r\n    color: #1976d2 !important;\r\n}\r\n\r\n.Mui-selected:focus,  .Mui-selected:active{\r\nbackground: none !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
